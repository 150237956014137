// import state from "./states";
// import * as actions from "./actions";
// import * as mutations from "./mutations";
// import * as getters from "./getters";
// import Auth from "../../../api/Auth";
import axios from "axios";
import * as Sentry from "@sentry/vue";

export const state = {
  country: [],
};
export const mutations = {
  set_country(state, payload) {
    state.country = payload;
  },
  setError: (state, payload) => {
    state.errors = payload;
  },
};
export const actions = {
  getCountry: async (context, payload, getters) => {
    try {
      let { data } = await axios.get("/country");

      context.commit("set_country", data);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      context.commit("setError", error);
    }
  },
};
export const getters = {};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
