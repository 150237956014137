<template>
  <div class="col-sm-12 mt-2">
    <div class="card border-0 p-2 shadow-sm card-notificationn-wrapper">
      <div
        class="d-flex flex-row justify-content-center align-items-center card-notification-content position-relative"
      >
        <button
          class="btn btn-secondary close-notification btn-sm bg-transparent position-absolute top-0 end-0"
          @click="closeNotification"
        >
          <i class="bi bi-x"></i>
        </button>
        <!-- :class="{ 'notification-readed': isReaded }" -> simpen di card-body -->
        <div class="card-body d-flex gap-3 align-items-center px-0 py-2">
          <div class="border-notification"></div>
          <template v-if="status_timeline_type === 1">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification"
            >
              <img :src="status_shipment_icon" class="" style="height: 26px" />
            </div>
          </template>

          <template v-else-if="status_timeline_type === 'scheduled'">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification"
            >
              <i class="bi bi-calendar-week"></i>
            </div>
          </template>
          <template v-else-if="status_timeline_type === 'finished'">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification"
            >
              <i class="bi bi-check-circle-fill"></i>
            </div>
          </template>
          <template v-else-if="status_timeline_type === 'packed'">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification"
            >
              <img src="@/assets/images/box-open.svg" width="16" />
            </div>
          </template>
          <template v-else-if="status_timeline_type === 2">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification"
            >
              <i class="bi bi-check-circle-fill"></i>
            </div>
          </template>
          <div class="d-flex flex-column gap-1 notification-text">
            <h5 class="card-title" v-if="status_timeline_type === 1">
              Your Job Order Has Been Updated to {{ status_shipment_title }}
            </h5>
            <h5 class="card-title" v-else-if="status_timeline_type === 2">
              Your Job Order Has Been Successfully Finished
            </h5>
            <h5 class="card-title" v-else-if="status_timeline_type === 0">
              Your Job Order Has Been Updated to {{ status_timeline_title }}
            </h5>

            <p
              class="card-text"
              v-if="id_sea !== null && status_timeline_type !== 2"
            >
              Order with <b>{{ jobfile_no }}, BL NO. {{ bl_number }}</b> has
              been at
              <b>{{ name_port ? name_port : '-' }}, {{ country_port ? country_port : '-' }}</b>
            </p>

            <p
              class="card-text"
              v-else-if="id_sea !== null && status_timeline_type === 2"
            >
              Order with <b>{{ jobfile_no }}, BL NO. {{ bl_number }}</b> has
              arrived at
              <b>{{ name_port ? name_port : '-' }}, {{ country_port ? country_port : '-' }}</b>
            </p>

            <p
              class="card-text"
              v-else-if="id_sea === null && status_timeline_type !== 2"
            >
              Order with <b>{{ jobfile_no }}, HAWB NO. {{ hawb_number }}</b> has
              been at
              <b>{{ name_port ? name_port : '-' }}, {{ country_port ? country_port : '-' }}</b>
            </p>
            <p
              class="card-text"
              v-else-if="id_sea === null && status_timeline_type === 2"
            >
              Order with <b>{{ jobfile_no }}, HAWB NO. {{ hawb_number }}</b> has
              arrived at
              <b>{{ name_port ? name_port : '-' }}, {{ country_port ? country_port : '-' }}</b>
            </p>
          </div>
        </div>
        <span class="time-notification position-absolute bottom-0 end-0">{{
          time
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    title: String,
    desc: String,
    status: String,
    time: String,
    isReaded: Boolean,

    id_sea: Number,

    status_shipment_title: String,
    status_timeline_title: String,

    jobfile_no: String,
    bl_number: String,
    hawb_number: String,

    name_port: String,
    country_port: String,

    status_shipment_icon: String,
    status_timeline_type: Number,

    isShow: Number,
    id: Number,
  },

  methods: {
    closeNotification() {
      axios
        .get(`/notifications/close-notification?id=${this.id}`)
        .then((res) => {
          console.log("Notifikasi berhasil ditutup");
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
      this.$emit("refresh-notif");
    },
  },
};
</script>

<style scoped>
/* mobile */
@media (min-width: 300px) {
  .card-notificationn-wrapper {
    width: fit-content !important;
  }
  .card div.card-notification-content .card-body h5.card-title {
    color: #28ae96;
    font-size: 12px;
  }

  .card div.card-notification-content .card-body p.card-text {
    color: #565656;
    font-size: 10px;
  }
  .time-notification {
    font-size: 0.6rem;
  }
  .sub-title-notification,
  .time-notification {
    color: #565656;
  }
}
/* tablet / horizontal mobile */
@media (min-width: 768px) {
  .card div.card-notification-content .card-body h5.card-title {
    color: #28ae96;
    font-size: 1.1rem;
  }

  .card div.card-notification-content .card-body p.card-text {
    color: #565656;
    font-size: 0.9rem;
  }

  .time-notification {
    font-size: 0.8rem;
  }
  .sub-title-notification,
  .time-notification {
    color: #565656;
  }
}
/* desktop */
@media (min-width: 992px) {
  .card-notificationn-wrapper {
    width: 100% !important;
  }
}
.notification-text h5 {
  font-size: 8px;
}
.notification-readed .border-notification {
  background-color: #b0b0b0;
  width: 6px !important;
  height: 80px !important;
  border-radius: 8px;
}

.notification-text {
  width: 85% !important;
}

.btn.btn-secondary.close-notification {
  padding: 0;
  margin-right: -8px;
  color: #ff6d6d;
  border: transparent;
  font-size: 1.5rem !important;
}

.btn.btn-secondary.close-notification:hover {
  opacity: 0.8;
  padding: 0;
  margin-right: -4px;
  color: #ff6d6d;
  border: transparent;
  font-size: 1.5rem !important;
}

.card-notification.content .btn-close {
  width: 0.9rem !important;
  height: 0.9rem !important;
}
.border-notification {
  background-color: #28ae96;
  width: 6px !important;
  height: 80px !important;
  border-radius: 8px;
}
.card div.card-notifiocation.content .border-start {
  border-left: 6px solid #28ae96 !important;
  border-radius: 8px;
}

.notification-content h6 {
  color: #28ae96;
  font-size: 1.2rem;
}

.icon-content-notification {
  width: 30px;
  height: 30px;
  background-color: #e3f3f0;
  color: #28ae96;
}
</style>
