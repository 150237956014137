<template>
  <div
    class="col-sm-12 px-0 py-0 rounded d-flex flex-row justify-content-between align-items-center"
  >
    <div class="d-flex flex-column align-self-center data-detail">
      <p class="font-poppins awb">AWB or B/L (Bill of Lading)</p>
      <p class="font-roboto text-muted awb">{{ number ? number : "-" }}</p>
    </div>
    <div class="d-flex flex-row gap-2">
      <img
        v-if="status === 2"
        src="@/assets/images/verified-icon.svg"
        class="img-fluid verified align-self-start align-self-md-center mt-1 mt-md-0"
      />
      <div class="d-flex flex-column align-self-center data-detail">
        <p class="font-roboto">Date received :</p>
        <p class="font-roboto text-muted" v-if="status === 2">
          {{ formattedDate }}
        </p>
        <p v-else>-</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    number: String,
    date: String,
    status: Number,
  },

  computed: {
    formattedDate() {
      const formatted = moment(this.date).format("DD MMM YYYY HH:mm [WIB]");
      return formatted;
    },
    // formattedDate() {
    //   // Menggunakan moment.js untuk memformat tanggal dengan zona waktu dinamis
    //   const formatted = moment(this.date)
    //     .tz("Asia/Jakarta")
    //     .format("DD MMM YYYY HH:mm z");
    //   return formatted;
    // },
  },
};
</script>

<style scoped>
/* data detail */
.img-fluid.verified {
  width: 24px;
}
.data-detail p {
  font-size: 14px;
  color: #626262;
  font-weight: 600;
}
.data-detail p.text-muted {
  font-size: 12px;
  color: #626262;
  font-weight: 400;
}
.data-detail p.awb {
  font-size: 11px !important;
  color: #626262 !important;
  font-weight: 400 !important;
}
.data-detail p.text-muted.awb {
  font-size: 20px !important;
  color: #626262 !important;
  font-weight: 700 !important;
}
</style>
