<template>
  <section class="container-fluid d-flex flex-column gap-4">
    <Header />
    <div
      class="container mx-auto col-sm-12 today-notification row rounded px-1 px-md-5 py-4 bg-white"
    >
      <div
        class="mb-4 col-sm-12 d-flex flex-column flex-md-row gap-2 gap-md-0 align-items-start align-items-md-center justify-content-between notification-content"
      >
        <h6 class="fw-bold">Notification</h6>
        <div
          class="d-flex align-self-stretch flex-row justify-content-between gap-0 gap-md-2 notification-actions"
        >
          <!-- <button class="btn btn-primary" @click="markAll()">
            <i class="bi bi-check-all me-1"></i>
            <span class="text-nowrap">Mark all as read</span>
          </button> -->
          <button class="btn btn-danger" @click="clearAll">
            <i class="bi bi-x me-1"></i>
            <span class="text-nowrap">Clear all</span>
          </button>
        </div>
      </div>
      <!-- 
                step:
                1. secara default list notifikasi maksimal 4 data
                2. jika isReadMore bernilai true && jumlah list notifikasinya lebih dari 4, loop semua list notifikasi
            -->
      <div class="col-sm-12">
        <p class="fw-bold sub-title-notification">Today</p>
      </div>
      <!-- sebagai contoh untuk notifikasi today, kita ambil 1 saja -->
      <!-- <template v-for="(notification, index) in notifications">
        <template v-if="index === 0">
          <CardNotification
            :time="notification.time"
            :isReaded="notification.is_readed"
            :title="notification.title"
            :desc="notification.description"
            :status="notification.status"
            :key="index + 'this_week'"
          />
        </template>
      </template> -->
      <template v-if="notificationsToday">
        <template
          v-for="(notification, index) in notificationsToday.slice(
            0,
            maxNotificationsToday
          )"
        >
          <CardNotification
            :time="formatTime(notification.created_at)"
            :key="index"
            :id_sea="notification.id_sea"
            :jobfile_no="notification.jobfile_no"
            :name_port="notification.name_port"
            :country_port="notification.country_port"
            :bl_number="notification.bl_number"
            :hawb_number="notification.hawb_number"
            :status_shipment_title="notification.status_shipment_title"
            :status_timeline_title="notification.status_timeline_title"
            :status_timeline_type="notification.status_timeline_type"
            :status_shipment_icon="notification.status_shipment_icon"
            :isShow="notification.isShow"
            :id="notification.id"
            @refresh-notif="refreshData"
          />
        </template>
      </template>
      <div class="col-sm-12 mt-4">
        <p class="fw-bold sub-title-notification">This Week</p>
      </div>
      <template>
        <template
          v-for="(notification, index) in notificationsThisWeek.slice(
            0,
            maxNotificationsThisWeek
          )"
        >
          <CardNotification
            :time="formatTime(notification.created_at)"
            :key="index + 'this_week'"
            :id_sea="notification.id_sea"
            :jobfile_no="notification.jobfile_no"
            :name_port="notification.name_port"
            :country_port="notification.country_port"
            :bl_number="notification.bl_number"
            :hawb_number="notification.hawb_number"
            :status_shipment_title="notification.status_shipment_title"
            :status_timeline_title="notification.status_timeline_title"
            :status_timeline_type="notification.status_timeline_type"
            :status_shipment_icon="notification.status_shipment_icon"
            :isShow="notification.isShow"
            :id="notification.id"
            @refresh-notif="refreshData"
          />
        </template>
      </template>
      <!-- <template v-for="(notification, index) in notifications">
        <template v-if="index > 1 && index <= maxNotifications">
          <CardNotification
            :time="notification.time"
            :isReaded="notification.is_readed"
            :title="notification.title"
            :desc="notification.description"
            :status="notification.status"
            :key="index + 'this_week'"
          />
        </template>
      </template> -->
      <button
        v-if="
          this.notificationsToday.length > 4 ||
          this.notificationsThisWeek.length > 4
        "
        class="btn btn-primarys mt-4 py-2 bottom-actions"
        @click="toggleReadNotifications"
      >
        <span v-if="isReadMore">Show Less</span>
        <span v-if="!isReadMore">Show All</span>
      </button>
      <a
        class="btn btn-secondary mt-4 px-4 py-2 bottom-actions ms-auto"
        href="/Dashboard"
      >
        Back
      </a>
    </div>
    <MainFooter />
  </section>
</template>

<script>
import Header from "@/components/general/Header.vue";
import CardNotification from "@/components/general/header/micro/CardNotification.vue";
import notifications from "@/assets/json/general/notifications.json";
import MainFooter from "@/components/general/MainFooter.vue";
import swal from "sweetalert2";

import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "NotificationsView",
  components: { Header, CardNotification, MainFooter },
  data() {
    return {
      notifications: notifications,
      maxNotificationsToday: 4, // limit data
      maxNotificationsThisWeek: 4, // limit data
      isReadMore: false,
      notificationsToday: [],
      notificationsThisWeek: [],
    };
  },

  computed: {
    ...mapState(["userLogin"]),
  },

  mounted() {
    this.fetchDataNotification();
  },
  methods: {
    toggleReadNotifications() {
      this.isReadMore = !this.isReadMore;
      if (!this.isReadMore) {
        this.maxNotificationsToday = 4;
        this.maxNotificationsThisWeek = 4;
      } else {
        this.maxNotificationsToday = this.maxNotificationsToday.length;
        this.maxNotificationsThisWeek = this.maxNotificationsThisWeek.length;
      }
    },
    markAll() {
      new swal({
        title: "Are you sure you want to mark all as read?",
        text: "",
        // icon: 'success',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        // timer: 1500,
        showLoaderOnConfirm: false,
        customClass: {
          container: "swal-container-oaktree",
          popup: "swal-popup-oaktree",
          header: "swal-header-oaktree",
          title: "swal-title-oaktree",
          icon: "swal-icon-success-oaktree",
          image: "swal-image-oaktree",
          content: "swal-content-oaktree",
          input: "swal-input-oaktree",
          actions: "swal-actions-oaktree",
          confirmButton: "swal-confirm-button-oaktree",
          denyButton: "swal-confirm-button-oaktree",
          cancelButton: "swal-cancel-button-oaktree",
          footer: "swal-footer-oaktree",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Marked All");
        }
      });
    },
    clearAll() {
      new swal({
        title: "Are you sure you want to clear all notification?",
        text: "",
        // icon: 'success',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        // timer: 1500,
        showLoaderOnConfirm: false,
        customClass: {
          container: "swal-container-oaktree",
          popup: "swal-popup-oaktree",
          header: "swal-header-oaktree",
          title: "swal-title-oaktree",
          icon: "swal-icon-success-oaktree",
          image: "swal-image-oaktree",
          content: "swal-content-oaktree",
          input: "swal-input-oaktree",
          actions: "swal-actions-oaktree",
          confirmButton: "swal-confirm-button-oaktree",
          denyButton: "swal-confirm-button-oaktree",
          cancelButton: "swal-cancel-button-oaktree",
          footer: "swal-footer-oaktree",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("Clear All", result.isConfirmed);
          axios
            .get(
              `/notifications/close-notifications?id=${this.$store.state.userLogin.id}`
            )
            .then((res) => {
              console.log("Clear All: Success");
              this.fetchDataNotification();
            })
            .catch((error) => {
              Sentry.captureException(error);
            });
        }
      });
    },

    refreshData() {
      this.fetchDataNotification();
    },

    fetchDataNotification() {
      axios
        .get(
          `/notifications/get-notifications?id=${this.$store.state.userLogin.id}`
        )
        .then((res) => {
          this.notificationsToday = res.data.today;
          this.notificationsThisWeek = res.data.thisweek;
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },

    formatTime(date) {
      const formatted = moment(date).format("HH:mm");
      return formatted;
    },
  },
};
</script>

<style scoped>
.btn-primarys.bottom-actions {
  width: 100%;
  background-color: transparent !important;
  border: transparent;
  color: #28ae96 !important;
}
.btn-primarys.bottom-actions:hover {
  width: 100%;
  background-color: transparent !important;
  border: transparent;
  color: #28ae96 !important;
}
.btn-secondary.bottom-actions {
  width: fit-content !important;
  background-color: transparent;
  border: transparent;
  background-color: #28ae96;
  color: #ffffff;
}
.btn-secondary.bottom-actions:hover {
  width: fit-content !important;
  opacity: 0.8;
  background-color: #28ae96;
  border: transparent;
  background-color: #28ae96;
  color: #ffffff;
}
.sub-title-notification,
.time-notification {
  color: #565656;
}
.notification-actions .btn-primary {
  background-color: #28ae96;
  border-radius: 8px;
  border: transparent;
  font-size: 0.9rem;
}
.notification-actions .btn-primary:hover {
  opacity: 0.8;
  background-color: #28ae96;
  border-radius: 8px;
  border: transparent;
  font-size: 0.9rem;
}
.notification-actions .btn-danger {
  background-color: #ff6d6d;
  border-radius: 8px;
  border: transparent;
  font-size: 0.9rem;
}
.notification-actions .btn-danger:hover {
  opacity: 0.8;
  background-color: #ff6d6d;
  border-radius: 8px;
  border: transparent;
  font-size: 0.9rem;
}
.notification-content h6 {
  color: #28ae96;
  font-size: 1.2rem;
}
</style>
