import Vue from "vue";
// import VueSweetalert2 from 'vue-sweetalert2'
import Swal from "sweetalert2";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import "./config/Api";
// import PortalVue from 'portal-vue'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "sweetalert2/src/sweetalert2.scss";
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import 'jquery/src/jquery.js'
import "bootstrap";
// import 'bootstrap/scss/bootstrap.scss';
import Select2 from "v-select2-component";
import ToggleButton from "vue-js-toggle-button";
import "@/plugins/apexcharts";

import * as Sentry from "@sentry/vue";

// import 'bootstrap/dist/js/bootstrap.min.js'

// var $ = require('jquery')

Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.component("Select2", Select2);
Vue.use(ToggleButton);

// Vue.prototype.$userLogin = 'tes'
// Vue.use(PortalVue)
// Vue.use(Swal)

// import Swal from 'sweetalert2';
// window.Swal = Swal;

Sentry.init({
  Vue,
  dsn: "https://10454500db6c0aaf1f885485cffa715d@o4505945610125312.ingest.sentry.io/4505951423889408",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https?:\/\/localhost:8000\/api\//,
    /^https?:\/\/api-dummy-shipments\.rimbahouse\.com\/api\//,
    /^https?:\/\/hidden-api-tracking\.oaktree\.id\/api\//,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default new Vue({
  vuetify,
  Swal,
  router,
  axios,
  store,

  async created() {
    window.axios = axios;
    // axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
    // console.log(,'tokne')
    if (localStorage.getItem("token")) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
    }
  },
  render: (h) => h(App),
}).$mount("#app");
