import LoginBanner from "../../../api/LoginBanner";

export const getLoginBanner = async({ commit }, payload) => {
    return await LoginBanner.show(payload)
        .then((response) => {
            commit("SET_DATATS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};