<template>
  <section class="col-sm-12 container mx-auto px-2 px-md-4">
    <div class="d-flex justify-content-between">
      <!-- <IconStatus
        :key="defaultStatus.status"
        :status="defaultStatus.status"
        :isConfirmed="defaultStatus.confirmed"
        :icon="defaultStatus.icon"
      /> -->
      <!-- <template v-for="tStatus in trackingStatuss">
        <IconStatus
          :key="tStatus.status"
          :status="tStatus.status"
          :isConfirmed="tStatus.confirmed"
        />
      </template> -->
      <IconStatus
        :key="defaultStatus.status"
        :status="defaultStatus.status"
        :icon="defaultStatus.icon"
        :type="defaultStatus.type"
      />
      <template v-for="(tStatus, index) in trackingStatus">
        <IconStatus
          :key="index"
          :status="tStatus.status_timeline.status_shipment?.title"
          :icon="tStatus.status_timeline.status_shipment?.icons"
          :type="tStatus.status_timeline.type"
        />
      </template>
    </div>
    <div class="bar-status rounded-pill"></div>
  </section>
</template>

<script>
import IconStatus from "@/components/Tracking/atoms/IconStatus.vue";
export default {
  components: { IconStatus },
  // props: {
  //     stage: "scheduled",
  //     valueStep: 1,
  // },
  props: ["trackingStatus"],
  data() {
    return {
      // trackingStatuss: [
      //   { status: "scheduled", confirmed: true },
      //   { status: "packed", confirmed: true },
      //   { status: "delivery_port", confirmed: true },
      //   { status: "delivery_cargo", confirmed: true },
      //   { status: "finished", confirmed: true },
      // ],
      defaultStatus: [{ status: "default", icon: "default", type:'default' }],
    };
  },
  mounted() {
    // console.log("Tracking Status Data:", this.trackingStatus);
  },
};
</script>

<style scoped>
/* mobile */
@media (min-width: 300px) {
  .bar-status {
    background-color: #e3f3f0;
    height: 4px;
    width: 95%;
    margin: 0 auto;
    z-index: 1;
    margin-top: -40px !important;
  }
}
/* tablet / horizontal mobile */
@media (min-width: 768px) {
  .bar-status {
    background-color: #e3f3f0;
    height: 4px;
    width: 95%;
    margin: 0 auto;
    z-index: 1;
    margin-top: -60px !important;
  }
}
/* desktop */
@media (min-width: 992px) {
}
section.col-sm-12 {
  height: 90px;
}
</style>
