<template>
  <section class="container-fluid d-flex flex-column gap-4">
    <Header />
    <MenuGroup activeMenu="dashboard" />
    <Summary />
    <!-- CHART -->
    <div class="container mx-auto p-0 row">
      <div class="col-12 col-md-12 col-lg-8 ps-0 pe-0 pe-lg-4">
        <div class="d-flex flex-column px-2 py-3 m-0 rounded bg-white">
          <div
            class="d-flex flex-column flex-md-row px-2 justify-content-start justify-content-md-between align-items-stretch align-items-md-center mb-4 gap-4 gap-md-0"
          >
            <h6 class="heading-title font-roboto">Job Order Statistic</h6>
            <div class="d-flex flex-column flex-md-row gap-2">
              <!-- <select
                class="form-select pe-5"
                aria-label="select type range"
                v-model="typeRange"
              >
                <template v-for="opTyperange in optionsTyperange">
                  <option
                    :value="opTyperange.value"
                    :key="opTyperange.value + 'type'"
                  >
                    {{ opTyperange.text }}
                  </option>
                </template>
              </select> -->

              <!-- OptionsTyperange Select Component -->
              <select
                class="form-select"
                aria-label="select type range"
                v-model="typeRange"
              >
                <option
                  v-for="opTyperange in optionsTyperange"
                  :value="opTyperange.value"
                  :key="opTyperange.value + 'type'"
                >
                  {{ opTyperange.text }}
                </option>
              </select>
              <!-- End OptionsTyperange Select Component -->

              <!-- Filter Monthly Select Component -->
              <template v-if="typeRange === 'monthly'">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="rangeMonth"
                >
                  <template v-for="(opRangeMonth, index) in optionsRangeMonth">
                    <option
                      :value="opRangeMonth.value"
                      :key="opRangeMonth.value + '_month' + index"
                    >
                      {{ opRangeMonth.text }}
                    </option>
                  </template>
                </select>

                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="rangeYear"
                >
                  <option
                    v-for="(year, index) in yearData"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </template>
              <!-- End Filter Monthly Select Component -->

              <!-- Filter Annualy Select Component -->
              <template v-if="typeRange === 'annualy'">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="rangeYear"
                >
                  <!-- <option
                    v-for="opRangeYear in optionsRangeYear"
                    :value="opRangeYear.value"
                    :key="opRangeYear.value + 'year_anually'"
                  >
                    {{ opRangeYear.text }}
                  </option> -->
                  <option
                    v-for="(year, index) in yearData"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </template>
              <!-- End Filter Annualy Select Component -->
            </div>
          </div>
          <JobStatistic
            v-if="rangeYear"
            :typeRange="typeRange"
            :rangeYear="parseInt(rangeYear)"
            :rangeMonth="rangeMonth"
          />
        </div>
      </div>
      <div
        class="col-12 col-md-12 col-lg-4 mt-4 mt-lg-0 py-4 px-4 rounded bg-white"
      >
        <div class="d-flex flex-column gap-5">
          <h6 class="heading-title font-roboto">Total Delivery</h6>
          <DeliveryStatistic
            v-if="rangeYear"
            :typeRange="typeRange"
            :rangeYear="parseInt(rangeYear)"
            :rangeMonth="rangeMonth"
          />
        </div>
      </div>
    </div>
    <!-- /CHART -->
    <!-- TABLES -->
    <div
      class="container mx-auto p-0 row bg-white rounded px-1 px-md-2 py-3 py-md-4 gap-4"
    >
      <div class="col-sm-12">
        <h6 class="heading-title font-roboto">Daily Delivery Order</h6>
      </div>
      <div class="col-sm-12 d-flex flex-column gap-4">
        <!-- <template v-for="deliveryOrder in deliveryOrders.slice(0, 2)">
          <DeliveryOrder
            :key="deliveryOrder.id"
            :jobNumber="deliveryOrder.jobNumber"
            :blNumber="deliveryOrder.blNumber"
            :customer="deliveryOrder.customer"
            :vessel="deliveryOrder.vessel"
            :eta="deliveryOrder.eta"
            :etd="deliveryOrder.etd"
            :salesMarketing="deliveryOrder.salesMarketing"
            :status="deliveryOrder.status"
            :idOrder="deliveryOrder.id"
          />
        </template> -->
        <template v-for="deliveryOrder in dailyDeliveryOrderData">
          <DeliveryOrder
            :key="deliveryOrder.id"
            :jobNumber="deliveryOrder.jobfile_no"
            :blNumber="deliveryOrder.bl_number"
            :hawbNumber="deliveryOrder.hawb_number"
            :flightNumber="deliveryOrder.flight_number"
            :customer="deliveryOrder.customer"
            :vessel="deliveryOrder.vessel"
            :eta="deliveryOrder.eta"
            :etd="deliveryOrder.etd"
            :salesMarketing="deliveryOrder.salesMarketing"
            :status="deliveryOrder.status"
            :idJobfile="deliveryOrder.id"
            :id_sea="deliveryOrder.sea"
            :id_info="deliveryOrder.info"
          />
        </template>
      </div>
    </div>
    <!-- /TABLES -->
    <MainFooter />
  </section>
</template>

<script>
import Header from "@/components/general/Header.vue";
import MenuGroup from "@/components/general/MenuGroup.vue";
import Summary from "@/components/general/Summary.vue";
import JobStatistic from "@/components/dashboard/JobStatistic.vue";
import DeliveryStatistic from "@/components/dashboard/DeliveryStatistic.vue";
import DeliveryOrder from "@/components/dashboard/DeliveryOrder.vue";
import dataTracking from "@/assets/json/tracking/dataTracking.json";
import MainFooter from "@/components/general/MainFooter.vue";

import { mapState, mapActions, mapGetters } from "vuex";

import axios from "axios";

export default {
  name: "DashboardView",
  components: {
    Header,
    MenuGroup,
    Summary,
    JobStatistic,
    DeliveryStatistic,
    DeliveryOrder,
    MainFooter,
  },
  data() {
    return {
      typeRange: "monthly",
      rangeYear: 2023,
      rangeMonth: "january",

      optionsTyperange: [
        { text: "Monthly", value: "monthly" },
        { text: "Annualy", value: "annualy" },
      ],
      optionsRangeYear: [
        { text: "2022", value: "2022" },
        { text: "2021", value: "2021" },
        { text: "2020", value: "2020" },
      ],
      optionsRangeMonth: [
        { text: "January", value: "january" },
        { text: "February", value: "february" },
        { text: "March", value: "march" },
        { text: "April", value: "april" },
        { text: "May", value: "may" },
        { text: "June", value: "june" },
        { text: "July", value: "july" },
        { text: "August", value: "august" },
        { text: "September", value: "september" },
        { text: "October", value: "october" },
        { text: "November", value: "november" },
        { text: "December", value: "december" },
      ],
      deliveryOrders: dataTracking,
      dailyDeliveryOrderData: [],
      yearData: [],
    };
  },

  computed: {
    ...mapState(["userLogin"]),
  },

  created() {
    const currentDate = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentMonth = monthNames[currentDate.getMonth()].toLowerCase();
    const currentYear = currentDate.getFullYear();
    this.rangeYear = currentYear;
    this.rangeMonth = currentMonth;
  },

  mounted() {
    this.generateYearData();
    this.fetchDataDailyOrder();
    this.getOptionsRangeYear();
  },

  methods: {
    ...mapActions("dailyDeliveryOrder", ["getDailyDeliveryOrder"]),

    fetchDataDailyOrder() {
      this.getDailyDeliveryOrder(this.$store.state.userLogin.id)
        .then((res) => {
          this.dailyDeliveryOrderData = res;
          // console.log("dailyDeliveryOrder : ", this.dailyDeliveryOrderData);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },

    generateYearData() {
      const currentYear = new Date().getFullYear();
      for (let i = 0; i <= 5; i++) {
        this.yearData.push(currentYear - i);
      }
    },

    getOptionsRangeYear() {
      axios
        .get(
          `/shipment-access-user/get-shipment-tracking-year?id=${this.$store.state.userLogin.id}`
        )
        .then((res) => {
          this.optionsRangeYear = res.data.map((year) => ({
            text: year,
            value: year,
          }));
          const currentYear = new Date().getFullYear().toString();
          if (
            this.optionsRangeYear.find((option) => option.value === currentYear)
          ) {
            this.rangeYear = currentYear;
          }
          // console.log(currentYear);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
  },
};
</script>

<style scoped>
/* select option */

.heading-title {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #686868 !important;
}
.form-select {
  background-color: #28ae96 !important;
  border: 1px solid transparent;
  color: #ffffff !important;
  width: fit-content !important;
  background-image: url("../assets/images/down-icon.svg") !important;
}
.form-select option {
  width: fit-content !important;
  color: #ffffff !important;
}
</style>
