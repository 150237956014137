<template>
  <section
    class="container mx-auto row flex-wrap px-4 py-4 bg-white rounded gap-4 gap-md-0"
  >
    <CardSummary
      imgUrl="summary-forwarder.png"
      context="Forwarder:"
      :value="this.userLogin.shipment_access.company.name_company"
      isForwarder
    />
    <CardSummary
      imgUrl="summary-phone.png"
      context="Phone Number:"
      :value="this.userLogin.shipment_access.company.phone_company"
      textColor="#28AE96"
    />
    <!-- <CardSummary
      imgUrl="summary-address.png"
      context="Address:"
      :value="userLogins.shipment_access.customer.address"
      textColor="#F9A707"
    /> -->
    <CardSummary
      imgUrl="summary-address.png"
      context="Address:"
      :value="this.userLogin.shipment_access.company.address_company"
      textColor="#F9A707"
    />
    <CardSummary
      imgUrl="summary-code.png"
      context="Customer Code:"
      :value="this.userLogin.shipment_access.customer.customer_code"
      textColor="#009FC2"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import summary from "@/assets/json/general/summary.json";
import CardSummary from "@/components/general/summary/CardSummary.vue";
export default {
  components: { CardSummary },
  data() {
    return {
      dataSummary: summary,
      dataProfiles: {
        fullname: "",
        email: "",
        accountExpired: "",
        phoneNumber: "",
        country: "",
        city: "",
        address: "",
      },
    };
  },

  computed: {
    ...mapState(["userLogin"]),
    ...mapState("auth", ["userLogins"]),
  },
  methods: {
    ...mapActions("auth", ["getLoginUser"]),
    ...mapActions("profile", ["getProfile"]),

    dataProfile() {
      this.getProfile(this.userLogin.id)
        .then((res) => {
          if (res) {
            this.dataProfiles.email = res.email;
            this.dataProfiles.fullname = res.fullname;
            this.dataProfiles.phoneNumber = res.phone_number;
            this.dataProfiles.address = res.address;
            if (res.city !== null) {
              this.dataProfiles.city = res.city.name;
            }
            if (res.country !== null) {
              this.dataProfiles.country = res.country.name;
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
  },
  mounted() {
    this.getLoginUser(this.userLogin.id);
    // this.dataProfile();
    // console.log(this.userLogin.shipment_access.company.name_company);
  },
};
</script>

<style scoped>
.summary-content p {
  font-size: 0.8rem;
  color: #aeaeae;
}
.summary-content h6 {
  font-size: 0.9rem;
  color: #565656;
}
.img-fluid.img-summary {
  width: 40px;
  height: 40px;
  object-fit: cover;
  background-size: cover;
}
.img-fluid.img-summary-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  background-size: cover;
}
</style>
