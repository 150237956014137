// import state from "./states";
// import * as actions from "./actions";
// import * as mutations from "./mutations";
// import * as getters from "./getters";
// import Auth from "../../../api/Auth";
import axios from "axios";
import * as Sentry from "@sentry/vue";

export const state = {
  profile: [],
};
export const mutations = {
  set_profile(state, payload) {
    state.profile = payload;
  },
  setError: (state, payload) => {
    state.errors = payload;
  },
};
export const actions = {
  getProfile: async (context, payload, getters) => {
    try {
      let { data } = await axios.get("/profile/get-profile/" + payload);

      context.commit("set_profile", data);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      context.commit("setError", error);
    }
  },
  saveProfiles: async (context, payload, getters) => {
    try {
      let formData = new FormData();
      // console.log(payload.payload,'imgdatas');
      formData.append("file", payload.payload.imageData);
      formData.append("email", payload.payload.email);

      let { data } = await axios.post(`${"/profile/store"}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // context.commit('set_profile', data)
      return data;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      context.commit("setError", error);
    }
  },
  emailNotif: async (context, payload, getters) => {
    try {
      let { data } = await axios.post(`${"/profile/email-notif"}`, payload);

      // context.commit('set_profile', data)
      return data;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      context.commit("setError", error);
    }
  },
};
export const getters = {};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
