<template>
    <section class="d-flex flex-column p-0 m-0">
        <div class="container-fluid d-flex flex-column gap-4">
            <Header />
            <MenuGroup activeMenu="profile" />
            <Summary />
                       
            <Profiles />            
        </div>
        <div class="container-fluid email-notification gap-4">
            <EmailComp />
        </div>
        <MainFooter />
    </section>
</template>

<script>
    import Header from "@/components/general/Header.vue"
    import MenuGroup from "@/components/general/MenuGroup.vue"
    import Summary from "@/components/general/Summary.vue"
    import DataProfile from "@/components/Profile/DataProfile.vue"
    import Profiles from "@/components/Profiles/Profile.vue"
    import MainFooter from "@/components/general/MainFooter.vue"    
    import EmailComp from "@/components/Profiles/EmailComp.vue"    

    export default {
        name: "ProfileView",
        components: { Header, MenuGroup, Summary, DataProfile, MainFooter, EmailComp, Profiles }
    }
</script>

<style scoped>    
    .form-switch .form-check-input {
        width: 40px;
        height: 20px;
        margin-left: -2.5em;        
        background-position: left center;
        border-radius: 2em;
        transition: background-position .15s ease-in-out;
    }
    .form-switch .form-check-input:focus {
        box-shadow: none;
        border: 1px solid #FFE299;
    }
    .form-switch .form-check-input:checked {
        background-position: right center;
        background-image: url("@/assets/icons/dot-newest.svg");
    }
    .form-check-input:checked {
        background-color: #FFE299;
        border-color: #FFE299;
    }
    .email-notification {
        font-weight: 700;
        font-size: 14px;        
        color: #565656;
    }
</style>