<template>
    <div>
        <!-- <ProfileEditComp /> -->
    </div>
</template>

<script>
    // import ProfileEditComp from "@/components/Profile/ProfileEdit.vue";
    export default {
        name: "ProfileEdit",
        components: {
            // ProfileEditComp,
        },
    };
</script>