import { render, staticRenderFns } from "./DeliveryOrder.vue?vue&type=template&id=44e22b29&"
import script from "./DeliveryOrder.vue?vue&type=script&lang=js&"
export * from "./DeliveryOrder.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryOrder.vue?vue&type=style&index=0&id=44e22b29&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports