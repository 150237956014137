<template>
  <div
    class="d-flex flex-column gap-2 justify-content-center align-items-center"
    v-if="type !== 0"
  >
    <template>
      <div
        class="icon-status-wrapper rounded-circle d-flex justify-content-center align-items-center"
      >
        <!-- <img src="@/assets/images/box-open.svg" class="icon-status" /> -->
        <template v-if="type !== 2">
          <img
            :src="icon ? icon : require('@/assets/images/box-open.svg')"
            class="icon-status"
          />
        </template>
        <template v-if="type === 2">
          <img src="@/assets/images/verified-icon.svg" class="icon-status" />
        </template>
      </div>
      <p class="status-text font-roboto text-nowrap">
        <template v-if="type !== 2">
          {{ status ? status : "Proses Penjadwalan" }}
        </template>
        <template v-else> Finished </template>
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    // isConfirmed: Boolean,
    icon: String,
    type: Number,
  },
};
</script>

<style scoped>
@media (min-width: 300px) {
  .status-text {
    color: #37ab96;
    font-size: 11px !important;
    font-weight: 400;
    text-align: center;
  }
  .icon-status-wrapper {
    background-color: #e3f3f0;
    border: 1px solid #f2f2f2;
    width: fit-content !important;
    z-index: 2;
    width: 40px !important;
    height: 40px;
  }
  .icon-status-wrapper .icon-status {
    width: 14px !important;
    height: 16px !important;
  }
}
@media (min-width: 768px) {
  .status-text {
    color: #37ab96;
    font-size: 14px !important;
    font-weight: 400;
    text-align: center;
  }
  .icon-status-wrapper {
    background-color: #e3f3f0;
    border: 1px solid #f2f2f2;
    width: fit-content !important;
    z-index: 2;
    width: 76px !important;
    height: 76px;
  }
  .icon-status-wrapper .icon-status {
    width: 33px !important;
    height: 37px !important;
  }
}
.d-flex.flex-column.unconfirmed {
  filter: grayscale(1) !important;
}
</style>
