import ShipmentAccessUser from "../../../api/ShipmentAccessUser";

export const setShipmentAccessUserPassword = async({ commit }, payload) => {
    return await ShipmentAccessUser.activateAccount(payload)
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};
export const getUser = async({ commit }, payload) => {
    return await ShipmentAccessUser.getUser(payload)
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};