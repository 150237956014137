<template>
  <v-app>
    <!-- <v-main> -->
    <MainContent />

    <!-- </v-main> -->
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import MainContent from "./router/Main.vue";
import "./assets/css/style.bundle.css";

// test
// import './assets/plugins/global/plugins.bundle.css'
// import './assets/js/scripts.bundle.js'
// import './assets/plugins/global/plugins.bundle.js'

export default defineComponent({
  name: "App",
  components: {
    MainContent,
  },
});
</script>

<style lang="scss">
@import "~vuetify/src/components/VStepper/_variables.scss";

@media (min-width: 992px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding: 0 0 !important;
  }
}

.container-fluid {
  padding: 0px;
  margin: 0px;
}
body {
  // font-size: 13px !important;
  background-color: #f1f4f8 !important;
}

.v-application {
  background-color: #f1f4f8 !important;
}

.content-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-page {
  display: flex;
  // align-items: center;
  justify-content: center;
}

.flex-root {
  height: 100px;
}

.content {
  // display: flex;
  // align-content: center;
  width: 95%;
  border: #f27474;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background-color: white !important;
}

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

p {
  font-size: 16px;
}

label {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: var(--v-black-base);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
li {
  margin: 0 !important;
  padding: 0 !important;
}

.input-labels {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.v-main__wrap {
  // display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.v-btn--rounded {
  border-radius: 4px;
}

.v-text-field--rounded {
  border-radius: 4px !important;
}

/* Width */
.w-60 {
  width: 60%;
  margin: 0 auto;
}

.w-70 {
  width: 70%;
  margin: 0 auto;
}

.w-80 {
  width: 80%;
  margin: 0 auto;
}

.w-90 {
  width: 90%;
  margin: 0 auto;
}

.w-100 {
  width: 100%;
  margin: 0 auto;
}

/* Carousel */

.v-carousel__controls {
  .v-carousel__controls__item {
    margin: 0px !important;
  }

  .v-btn--active {
    &:before {
      display: none;
    }

    .v-icon {
      opacity: 1;
    }
  }
}

.v-skeleton-loader__image {
  height: 100vh !important;
}

/* Sweet Alert */
.swal-popup-oaktree {
  padding: 30px !important;
  width: auto !important;
  min-width: 320px !important;
  max-width: 360px !important;
  overflow: hidden !important;
  border-radius: unset !important;
}
.swal-title-oaktree {
  font-size: 12px !important;
  padding-bottom: 10px !important;
  margin-bottom: 0 !important;
  line-height: 1.5 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.swal-container-oaktree .swal2-content {
  font-size: 12px !important;
  color: #9e9e9e !important;
  padding-bottom: 10px !important;
  line-height: 1.5 !important;
}
.swal-icon-warning-oaktree .swal2-icon-content {
  font-size: 25px !important;
}
.swal-icon-warning-oaktree {
  width: 35px !important;
  height: 35px !important;
  border-color: #ff8f00 !important;
  border: 0.15em solid #ff8f00 !important;
  margin: 0.3em auto 0.9em !important;
  color: #ff8f00 !important;
}
.swal-icon-success-oaktree {
  transform: scale(0.45) !important;
  -webkit-transform: scale(0.45) !important;
  -moz-transform: scale(0.45) !important;
  margin: 10px auto !important;
  -webkit-transform-origin: top center !important;
  -moz-transform-origin: top center !important;
  transform-origin: top center !important;
  margin-bottom: -30px !important;
}
.swal-icon-cancel-oaktree {
  width: 35px !important;
  height: 35px !important;
  margin: 10px auto !important;
  border: 0.15em solid #f27474 !important;
}
// .swal2-icon.swal2-error [class^=swal2-x-mark-line]{
// 	width: 1.5em!important;
// 	height: .1em!important;
// 	top: 1em!important;
// }
// .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left]{
// 	left: .35em!important;
// }
// .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right]{
// 	right: .35em!important;
// }
.swal-actions-oaktree {
  margin-top: 10px !important;
  flex-direction: row-reverse !important;
}
.swal-confirm-button-oaktree {
  background-color: var(--v-bluelagoon-base) !important;
  width: 100px !important;
  border-radius: 3px !important;
  text-transform: capitalize !important;
  padding: 0.375rem 1rem !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
}
.swal-confirm-button-oaktree:focus,
.swal-cancel-button-oaktree:focus {
  box-shadow: unset !important;
}

.swal-cancel-button-oaktree {
  background-color: #c4c4c4 !important;
  width: 100px !important;
  border-radius: 3px !important;
  text-transform: capitalize !important;
  padding: 0.375rem 1rem !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
}

/* Home */
.home {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 40px;
}

/* Login Page */

.login-box {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  border-radius: 50px;
  overflow: hidden;

  .login-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.icon-box {
  padding: 5px;
  width: 120px;
  height: 120px;
  display: block;
  position: relative;
  margin: 0 auto;

  .icon-loginsuccess-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Forms */
.form-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .v-btn {
    text-transform: capitalize;
  }

  .v-btn.blue-lagoon {
    filter: drop-shadow(10px 10px 40px rgba(1, 114, 131, 0.3));
  }

  .error-message {
    background: var(--v-transparent-base) !important;
    color: var(--v-error-base) !important;
    font-size: 12px !important;
  }

  .v-text-field__details {
    display: none;
  }
}

.v-input {
  font-size: 12px !important;

  .v-label {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: var(--v-black-base) !important;
  }
}

.shadow-bluelagoon {
  filter: drop-shadow(10px 10px 40px rgba(1, 114, 131, 0.3));
}

.login-logo {
  max-width: 80px;
  margin: 0 auto;
}

.text-small {
  font-size: 12px;
}

/* Links */
.v-btn--text {
  margin-top: 12px;

  &:hover {
    &:before {
      display: none;
    }
  }

  .v-btn__content {
    text-transform: capitalize;
    letter-spacing: 0;
    color: var(--v-bluelagoon-base);
  }
}

/* Dialog */
.v-dialog {
  margin: 0 0 0 auto !important;
  max-width: 40%;
  border-radius: unset !important;
  box-shadow: none !important;
  max-height: unset !important;

  .v-card {
    min-height: 100vh;
    padding: 30px;
    border-radius: unset !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .v-card__actions {
      padding: 0 30px !important;

      .v-btn__content {
        text-transform: capitalize;
        letter-spacing: 0;
      }
    }
  }
}

.swal2-popup {
  font-family: Roboto, serif;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .login-box {
    height: 660px;
    margin: 30px 0;
  }

  .home {
    .form-box {
      padding-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .login-box {
    height: 300px;
    border-radius: 20px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
</style>
