<template>
    <div>
        <RecoveryPasswordComp />
    </div>
</template>
  
<script>
import RecoveryPasswordComp from "@/components/Authentications/RecoveryPassword.vue";
export default {
    name: "RecoveryPasswordView",
    components: {
        RecoveryPasswordComp,
    },
};
</script>
<style lang="scss" scoped>

</style>
  