import axios from 'axios'

const END_POINT = "/shipment-access-user";

export default {
    activateAccount(payload) {
        return axios.post(`${END_POINT}/change-password`, payload);
    },
    getUser(payload) {
        return axios.post(`${END_POINT}/get-user/` + payload);
    },
};