<template>
    <!-- <div class="font" id=""> -->
        <!-- <NavBar v-if="isLoggedIn" /> -->
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <!-- <back-to-top bottom="50px" right="50px" visibleoffset="200">
          <button type="button"  class="btn btn-sm btn-info btn-to-top"><i class="fa fa-chevron-up"></i></button>
        </back-to-top> -->
    <!-- <v-main> -->
      <!-- <div class="menu-page">
        <Menu v-if="isLoggedIn" />
      </div> -->
      
      <!-- <div class="content-page">
        <div class="content"> -->
          <!-- Start Content -->
          <!-- <div class="container-fluid"> -->
            <router-view />
          <!-- </div> -->
        <!-- </div> -->
  
        <!-- <Footer /> -->
      <!-- </div> -->
      <!-- <div class="footer-page">
        <Footer />
      </div> -->
    <!-- </v-main> -->
<!-- </div> -->
</template>

<script>
    // import NavBar from '../components/Navbar.vue'
    // import Menu from '../components/Menu.vue'
    // import DashboardWidget from '../components/DashboardWidget.vue'
    import Footer from '../components/FooterMain.vue'
    import { mapState, mapGetters } from "vuex";

    export default ({
    name: "Main",
    components: {
        // NavBar,
        // Menu,
        // DashboardWidget,
        Footer
    },

  data: () => ({
    isLoggedIn: ''
  }),

  computed: {
        ...mapState(["userLogin"]),
  },

  mounted() {
    // isLoggedIn(){
      // console.log(localStorage.getItem('auth.currentUser'));
      if(localStorage.getItem('auth.currentUser') !== null){
        this.$isLoggedIn = true
        this.isLoggedIn = true

        //global var
        this.$userLogin = JSON.parse(localStorage.getItem('auth.currentUser'))
      } else {
        this.isLoggedIn = false
      }
    // }
  }
});
</script>