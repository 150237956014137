var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-fluid d-flex flex-column gap-4"},[_c('Header'),_c('div',{staticClass:"container mx-auto col-sm-12 today-notification row rounded px-1 px-md-5 py-4 bg-white"},[_c('div',{staticClass:"mb-4 col-sm-12 d-flex flex-column flex-md-row gap-2 gap-md-0 align-items-start align-items-md-center justify-content-between notification-content"},[_c('h6',{staticClass:"fw-bold"},[_vm._v("Notification")]),_c('div',{staticClass:"d-flex align-self-stretch flex-row justify-content-between gap-0 gap-md-2 notification-actions"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.clearAll}},[_c('i',{staticClass:"bi bi-x me-1"}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Clear all")])])])]),_vm._m(0),(_vm.notificationsToday)?[_vm._l((_vm.notificationsToday.slice(
          0,
          _vm.maxNotificationsToday
        )),function(notification,index){return [_c('CardNotification',{key:index,attrs:{"time":_vm.formatTime(notification.created_at),"id_sea":notification.id_sea,"jobfile_no":notification.jobfile_no,"name_port":notification.name_port,"country_port":notification.country_port,"bl_number":notification.bl_number,"hawb_number":notification.hawb_number,"status_shipment_title":notification.status_shipment_title,"status_timeline_title":notification.status_timeline_title,"status_timeline_type":notification.status_timeline_type,"status_shipment_icon":notification.status_shipment_icon,"isShow":notification.isShow,"id":notification.id},on:{"refresh-notif":_vm.refreshData}})]})]:_vm._e(),_vm._m(1),[_vm._l((_vm.notificationsThisWeek.slice(
          0,
          _vm.maxNotificationsThisWeek
        )),function(notification,index){return [_c('CardNotification',{key:index + 'this_week',attrs:{"time":_vm.formatTime(notification.created_at),"id_sea":notification.id_sea,"jobfile_no":notification.jobfile_no,"name_port":notification.name_port,"country_port":notification.country_port,"bl_number":notification.bl_number,"hawb_number":notification.hawb_number,"status_shipment_title":notification.status_shipment_title,"status_timeline_title":notification.status_timeline_title,"status_timeline_type":notification.status_timeline_type,"status_shipment_icon":notification.status_shipment_icon,"isShow":notification.isShow,"id":notification.id},on:{"refresh-notif":_vm.refreshData}})]})],(
        this.notificationsToday.length > 4 ||
        this.notificationsThisWeek.length > 4
      )?_c('button',{staticClass:"btn btn-primarys mt-4 py-2 bottom-actions",on:{"click":_vm.toggleReadNotifications}},[(_vm.isReadMore)?_c('span',[_vm._v("Show Less")]):_vm._e(),(!_vm.isReadMore)?_c('span',[_vm._v("Show All")]):_vm._e()]):_vm._e(),_c('a',{staticClass:"btn btn-secondary mt-4 px-4 py-2 bottom-actions ms-auto",attrs:{"href":"/Dashboard"}},[_vm._v(" Back ")])],2),_c('MainFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('p',{staticClass:"fw-bold sub-title-notification"},[_vm._v("Today")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 mt-4"},[_c('p',{staticClass:"fw-bold sub-title-notification"},[_vm._v("This Week")])])
}]

export { render, staticRenderFns }