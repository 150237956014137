<template>
    <section class="container mx-auto p-0 rounded">
        <div class="btn-group border-0 d-none d-md-block rounded" role="group" aria-label="Basic example">
            <div class="d-flex">
                <a
                    href="/Dashboard"                
                    class="px-4 py-3 btn btn-primary d-flex gap-2 align-items-center m-0"
                    :class="{'disabled': activeMenu === 'dashboard'}"
                    @click="changeMenu('dashboard')"
                >
                    <i class="bi bi-grid-fill"></i>
                    <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                        <h6 class="text-dark fw-bold">Dashboard</h6>
                        <p class="text-muted">Overview</p>
                    </div>
                </a>
                <a
                    href="/Tracking"
                    class="px-4 py-3 btn btn-primary d-flex gap-2 align-items-center m-0" 
                    :class="{'disabled': activeMenu === 'tracking'}"
                    @click="changeMenu('tracking')"
                >
                    <img src="@/assets/icons/tracking.svg" />
                    <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                        <h6 class="text-dark fw-bold">Tracking</h6>
                        <p class="text-muted">Track Your Cargo</p>
                    </div>
                </a>
                <a
                    href="/Profile"
                    class="px-4 py-3 btn btn-primary d-flex gap-2 align-items-center m-0"
                    :class="{'disabled': activeMenu === 'profile'}"
                    @click="changeMenu('profile')"
                >
                    <i class="bi bi-person-fill"></i>
                    <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                        <h6 class="text-dark fw-bold">Profile</h6>
                        <p class="text-muted">Edit Your Profile</p>
                    </div>
                </a>
            </div>
        </div>
        <!-- DRAWER -->
        <template v-if="forDrawer">
            <div class="btn-group border-0 d-flex flex-column gap-2 rounded" role="group" aria-label="Basic example">                
                <a 
                    href="/Dashboard"
                    class="px-4 py-3 btn btn-primary d-flex gap-2 align-items-center m-0"
                    :class="{'disabled': activeDrawer === 'dashboard'}"
                    @click="changeMenu('dashboard')"
                >
                    <i class="bi bi-grid-fill"></i>
                    <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                        <h6 class="text-dark fw-bold">Dashboard</h6>
                        <p class="text-muted">Overview</p>                        
                    </div>
                </a>
                <a
                    href="/Tracking"
                    class="px-4 py-3 btn btn-primary d-flex gap-2 align-items-center m-0"
                    :class="{'disabled': activeDrawer === 'tracking'}"
                    @click="changeMenu('tracking')"
                >
                    <img src="@/assets/icons/tracking.svg" />
                    <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                        <h6 class="text-dark fw-bold">Tracking</h6>
                        <p class="text-muted">Track Your Cargo</p>                        
                    </div>
                </a>
                <a
                    href="/Profile"
                    class="px-4 py-3 btn btn-primary d-flex gap-2 align-items-center m-0"
                    :class="{'disabled': activeDrawer === 'profile'}"
                    @click="changeMenu('profile')"
                >
                    <i class="bi bi-person-fill"></i>
                    <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                        <h6 class="text-dark fw-bold">Profile</h6>
                        <p class="text-muted">Edit Your Profile</p>
                    </div>
                </a>            
            </div>
        </template>
        <!-- /DRAWER -->
        <!-- FOR MOBILE -->
        <template v-if="activeMenu === 'dashboard'">
            <a 
                href="/Dashboard" 
                class="px-4 py-3 btn btn-light d-flex gap-2 align-items-center m-0 d-block d-md-none"
            >
                <i class="bi bi-grid-fill"></i>
                <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                    <h6 class="text-dark fw-bold">Dashboard</h6>
                    <p class="text-muted">Overview</p>
                </div>
            </a>
        </template>
        <template v-if="activeMenu === 'tracking'">
            <a
                href="/Tracking"
                class="px-4 py-3 btn btn-light d-flex gap-2 align-items-center m-0 d-block d-md-none">
                <img src="@/assets/icons/tracking.svg" />
                <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                    <h6 class="text-dark fw-bold">Tracking</h6>
                    <p class="text-muted">Track Your Cargo</p>
                </div>
            </a>
        </template>
        <template v-if="activeMenu === 'profile'">
            <a 
                href="/Profile"
                class="px-4 py-3 btn btn-light d-flex gap-2 align-items-center m-0 d-block d-md-none">
                <i class="bi bi-person-fill"></i>
                <div class="d-flex flex-column gap-1 ms-2 align-items-start">
                    <h6 class="text-dark fw-bold">Profile</h6>
                    <p class="text-muted">Edit Your Profile</p>
                </div>
            </a>
        </template>
        <!-- /FOR MOBILE -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            activeDrawer: window.sessionStorage.getItem('activeMenu') != null ? window.sessionStorage.getItem('activeMenu') : 'dashboard',
        }
    },
    props: {
        activeMenu: String,
        forDrawer: Boolean,
    },    
    methods: {
        changeMenu(menu) {
            // this.activeDrawer = menu
            window.sessionStorage.setItem('activeMenu', menu)
        }
    }
}
</script>

<style scoped>
    /* mobile */
    @media (min-width: 300px) {
        section.container { background-color: #ffffff !important; }
        a { background-color: #ffffff !important; border: transparent; }
        a:hover { background-color: #ffffff !important; border: transparent; opacity: 0.6; }
    }
    /* tablet / horizontal mobile */
    @media (min-width: 768px) { 
        section.container { background-color: #E5EAEE !important; }
        a { background-color: #E5EAEE !important; border: transparent; }
        a:hover { background-color: #E5EAEE !important; border: transparent; opacity: 0.6; }
    }
    /* desktop */
    @media (min-width: 992px) { 
        
    }  
    .text-dark { color: #565656 !important; }
    .btn-group { border-radius: 0px !important; }
    a i { color: #25AD99; font-size: 1.3rem }
    a img { width: 1.3rem; }
    p, h6 { padding: 0; margin: 0; }
    p.text-muted { margin-top: -4px !important; font-size: 12px; }    
    a.disabled { background-color: #BDE3DD !important; border: transparent; opacity: 1; }    
</style>