<template>
  <div class="nav-item dropdown profile">
    <img
      :src="previewImage"
      alt="logo"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    />
    <div class="dropdown-menu profile dropdown-menu-end p-0">
      <a class="dropdown-item p-2 profile-account" href="/Profile"
        ><i class="bi bi-person-fill me-2"></i> Profile</a
      >
      <a class="dropdown-item p-2 profile-logout" @click.prevent="logout()"
        ><i class="bi bi-box-arrow-left me-2"></i> Logout</a
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      imgProfile:
        "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      previewImage: `${require("../../../assets/media/svg/profile/image-placeholder.svg")}`,
    };
  },
  mounted() {
    this.dataProfile();
    // console.log("res ini apa", this.userLogin.shipment_access.customer.logo);
    // if (this.userLogin.shipment_access.customer.logo) {
    //   this.previewImage = this.userLogin.shipment_access.customer.logo;
    // }
  },

  computed: {
    ...mapState(["userLogin"]),
  },

  methods: {
    ...mapActions("profile", ["getProfile"]),

    dataProfile() {
      this.getProfile(this.userLogin.id).then((res) => {
        if (res.image) {
            this.previewImage = `data:image/jpeg;base64,${res.image}`;
        }
      });
    },

    logout() {
      // console.log('ini lgoou')
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("auth.currentUser");
      this.$router.go("/");
    },
  },
};
</script>

<style scoped>
/* START::PROFILE */
.profile img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  background-size: cover;
}
.dropdown-menu.profile {
  border: 0;
}
.profile-account,
.profile-logout {
  font-size: 0.9rem;
  font-weight: bolder;
}
.profile-account {
  color: #28ae96;
}
.profile-account:hover {
  opacity: 0.8;
  color: #28ae96;
}
.profile-logout {
  color: #ff6d6d;
}
.profile-logout:hover {
  opacity: 0.8;
  color: #ff6d6d;
}
/* END::PROFILE */
</style>
