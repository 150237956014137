<template>
  <div class="time-line-item row px-2 px-md-4">
    <div
      class="col-4 col-md-2 col-lg-1 d-flex time-line-date flex-column align-self-start"
    >
      <p class="font-roboto">{{ date ? date : "-" }}</p>
      <p class="font-roboto text-muted">{{ time ? time : "-" }}</p>
    </div>
    <!-- class="col-1 d-flex flex-column flex-md-row justify-content-center align-items-center gap-2" 
      :class="{
        'flex-row': status === 1,
        'flex-column': status !== 1,
        'justify-content-center': status === 1,
      }"-->
    <div
      class="col-1 d-flex align-items-start justify-content-center"
      :class="{
        'align-items-start': status !== 0,
        // 'align-items-end': status === 4,
      }"
    >
      <template v-if="status === 2">
        <img src="@/assets/icons/dot-newest.svg" class="img-dot" />
      </template>
      <template v-if="status === 1">
        <img src="@/assets/icons/dot-latest.svg" class="img-dot" />
        <div class="w-25 bg-info"></div>
      </template>
      <template v-if="status === 0 || status === 4">
        <img src="@/assets/icons/dot-latest.svg" class="img-dot" />
      </template>
      <!-- <div class="bar-time-line"></div> -->
    </div>
    <!-- <div
      class="col-1 d-flex flex-column justify-content-center"
      :class="{
        'align-items-start': status === 1 && !isSmallScreen,
        'align-items-center': status !== 1 || isSmallScreen,
      }"
    >
      <template v-if="status === 2">
        <img src="@/assets/icons/dot-newest.svg" class="img-dot" />
      </template>
      <template v-else>
        <img src="@/assets/icons/dot-latest.svg" class="img-dot" />
      </template>
      <div
        class=""
        :class="{
          'bar-time-line': isSmallScreen,
        }"
      ></div>
    </div> -->
    <div
      class="col-7 col-md-9 col-lg-10 d-flex flex-column flex-md-row gap-4 justify-content-center align-items-center pt-0 pb-4"
    >
      <div class="d-flex flex-column" :class="{ 'w-25': !isSmallScreen }">
        <p class="font-roboto time-line-status">{{ title ? title : "-" }}</p>
        <template v-if="location !== null">
          <div class="d-flex align-items-center justify-content-start gap-1">
            <img
              src="@/assets/icons/time-line-location.png"
              class="img-fluid time-line-location"
            />
            <p class="font-roboto time-line-substatus text-nowrap">
              {{ location }}
            </p>
          </div>
        </template>
      </div>
      <div
        class="d-flex flex-row gap-0 w-100"
        :class="{ 'time-line-dialog': desc !== '' }"
      >
        <img
          v-if="desc !== ''"
          src="@/assets/icons/dialog-arrow.svg"
          class="img-fluid dialog-arrow"
        />
        <div
          class="px-4 py-3 bg-white rounded w-100"
          :class="{ border: desc !== '' }"
        >
          <p class="font-roboto time-line-desc">
            {{ desc }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOrder: Number,
    status: Number,
    title: String,
    desc: String,
    date: String,
    time: String,
    location: String,
  },

  data() {
    return {
      isSmallScreen: false,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      if (window.innerWidth < 768) {
        this.isSmallScreen = true;
      } else {
        this.isSmallScreen = false;
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    },
  },
};
</script>

<style scoped>
* {
  padding: 0 !important;
}
@media (max-width: 576px) {
  .w-25 {
    width: 100px;
  }
}
@media (max-width: 820px) {
  .gap-9 {
    gap: 0.5rem !important;
  }
}
/* start::timeline */
.img-dot {
  width: 20px;
  height: 20px;
  z-index: 10;
}
.time-line-dialog {
  min-height: 58px;
}
.img-fluid.time-line-location {
  width: 14px;
  height: 14px;
}
.time-line-date p,
p.time-line-status {
  font-size: 1rem;
  color: #585858;
  font-weight: 700;
}
.time-line-date p.text-muted,
p.time-line-substatus,
.time-line-desc {
  font-size: 1rem;
  color: #817f7f;
  font-weight: 400;
}
.img-fluid.dialog-arrow {
  margin-right: -10px;
  z-index: 1000;
}
.bar-time-line {
  height: 100%;
  width: 4px;
  background-color: #dbe8e5;
}
.time-line-item {
  min-height: 54px;
}
/* end::timeline */
</style>
