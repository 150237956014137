<template>
  <section class="row mx-auto p-0">
    <div
      class="col-sm-3 d-flex flex-column align-items-center justify-content-center"
    >
      <img :src="previewImage" class="img-fluid img-profile" />
      <div class="d-flex flex-column upload-wrapper">
        <label
          for="file-upload"
          class="custom-file-upload rounded-circle d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-camera-fill"></i>
        </label>
        <input id="file-upload" ref="fileInput" type="file" @input="pickFile" />
      </div>
    </div>
    <div
      class="mx-auto mx-md-0 col-sm-9 row d-flex flex-column justify-content-start align-items-start gap-4 px-2"
    >
      <form class="d-flex flex-column gap-4">
        <div
          class="alert alert-danger"
          role="alert"
          v-show="alert.Visible"
          v-text="alert.Message"
        ></div>
        <div class="col-sm-12 row">
          <div class="col-sm-6 mb-4">
            <div class="d-flex flex-column">
              <label class="font-roboto form-label">Full Name</label>
              <input
                autocomplete="full_name"
                type="text"
                class="form-control"
                placeholder="Full Name"
                @input="v$.profileData.fullname.$touch()"
                @blur="v$.profileData.fullname.$touch()"
                v-model="profileData.fullname"
              />
              <span
                class="error-message text-danger"
                v-if="v$.profileData.fullname.$error"
              >
                {{ v$.profileData.fullname.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="d-flex flex-column">
              <label class="font-roboto form-label">Email</label>
              <input
                autocomplete="email"
                type="email"
                class="form-control"
                placeholder="Email"
                @input="v$.profileData.email.$touch()"
                @blur="v$.profileData.email.$touch()"
                disabled
                v-model="profileData.email"
              />
              <span
                class="error-message text-danger"
                v-if="v$.profileData.email.$error"
              >
                {{ v$.profileData.email.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="d-flex flex-column">
              <label class="font-roboto form-label">Phone Number</label>
              <input
                autocomplete="phone-number"
                type="text"
                class="form-control"
                placeholder="Phone Number"
                @input="v$.profileData.phoneNumber.$touch()"
                @blur="v$.profileData.phoneNumber.$touch()"
                v-model="profileData.phoneNumber"
              />
              <span
                class="error-message text-danger"
                v-if="v$.profileData.phoneNumber.$error"
              >
                {{ v$.profileData.phoneNumber.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="d-flex flex-column">
              <label class="font-roboto form-label">Country</label>
              <v-autocomplete
                v-model="profileData.country"
                @input="v$.profileData.country.$touch()"
                @blur="v$.profileData.country.$touch()"
                outlined
                class="custom-height mt-1"
                :items="country"
                item-text="name"
                item-value="id"
                placeholder="Country"
              >
              </v-autocomplete>
              <span
                class="error-message text-danger mt-7"
                v-if="v$.profileData.country.$error"
              >
                {{ v$.profileData.country.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="d-flex flex-column">
              <label class="font-roboto form-label">City</label>
              <v-autocomplete
                v-model="profileData.city"
                @input="v$.profileData.city.$touch()"
                @blur="v$.profileData.city.$touch()"
                outlined
                class="custom-height mt-1"
                :items="city"
                item-text="name"
                item-value="id"
                placeholder="City"
              >
              </v-autocomplete>
              <span
                class="error-message text-danger mt-7"
                v-if="v$.profileData.city.$error"
              >
                {{ v$.profileData.city.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="d-flex flex-column">
              <label class="font-roboto form-label">Address</label>
              <input
                autocomplete="address"
                type="text"
                class="form-control"
                placeholder="Address"
                @input="v$.profileData.address.$touch()"
                @blur="v$.profileData.address.$touch()"
                v-model="profileData.address"
              />
              <span
                class="error-message text-danger"
                v-if="v$.profileData.address.$error"
              >
                {{ v$.profileData.address.$errors[0].$message }}</span
              >
            </div>
          </div>
        </div>
      </form>
      <div
        class="col-sm-12 d-flex flex-column gap-4 gap-md-0 flex-md-row justify-content-center justify-content-md-end align-items-center pe-0 pe-md-5"
      >
        <button
          class="btn btn-outline-success font-roboto px-5 me-0 me-md-4"
          @click="cancel()"
        >
          Cancel
        </button>
        <button
          class="btn btn-success font-roboto px-5"
          :class="{
            disabled:
              profileData.fullname == '' ||
              profileData.email == '' ||
              profileData.phoneNumber == '' ||
              profileData.address == '' ||
              profileData.country == '' ||
              profileData.city == '',
          }"
          @click="submit()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
import dataProfile from "@/assets/json/profile/dataProfile.json";
import { mapActions, mapState, mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      dataProfile: dataProfile,
      fullname: "",
      email: "",
      phone_number: "",
      address: "",
      isBusy: false,
      alert: {
        Visible: "",
        Message: "",
      },
      previewImage: `${require("../../assets/media/svg/profile/image-placeholder.svg")}`,
      profileData: {
        imageData: "",
        fullname: "",
        email: this.$store.state.userLogin.email,
        phoneNumber: "",
        country: 0,
        city: 0,
        address: "",
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
  },

  validations() {
    return {
      profileData: {
        email: {
          required: helpers.withMessage("Email harus diisi.", required),
          email: helpers.withMessage("Email harus valid.", email),
        },
        fullname: {
          required: helpers.withMessage("Fullname harus diisi.", required),
        },
        phoneNumber: {
          required: helpers.withMessage("Phone number harus diisi.", required),
        },
        address: {
          required: helpers.withMessage("Address harus diisi.", required),
        },
        city: { required: helpers.withMessage("City harus diisi.", required) },
        country: {
          required: helpers.withMessage("Country harus diisi.", required),
        },
      },
    };
  },

  mounted() {
    this.getCity(), this.getCountry(), this.getProfileData();
  },

  computed: {
    ...mapState("country", ["country"]),
    ...mapState("city", ["city"]),
    ...mapState(["userLogin"]),
  },

  methods: {
    ...mapActions("city", ["getCity"]),
    ...mapActions("country", ["getCountry"]),
    ...mapActions("profile", ["saveProfiles", "getProfile"]),

    cancel() {
      new swal({
        title: "Are you sure you want to cancel changes?",
        text: "",
        // icon: 'success',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        // timer: 1500,
        showLoaderOnConfirm: false,
        customClass: {
          container: "swal-container-oaktree",
          popup: "swal-popup-oaktree",
          header: "swal-header-oaktree",
          title: "swal-title-oaktree",
          icon: "swal-icon-success-oaktree",
          image: "swal-image-oaktree",
          content: "swal-content-oaktree",
          input: "swal-input-oaktree",
          actions: "swal-actions-oaktree",
          confirmButton: "swal-confirm-button-oaktree",
          denyButton: "swal-confirm-button-oaktree",
          cancelButton: "swal-cancel-button-oaktree",
          footer: "swal-footer-oaktree",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        } else if (result.isCancelled) {
          return 0;
        }
      });
    },
    submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.isBusy = false;
        console.log("err");
      } else {
        new swal({
          title: "Are you sure you want to save changes?",
          text: "",
          // icon: 'success',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          // timer: 1500,
          showLoaderOnConfirm: false,
          customClass: {
            container: "swal-container-oaktree",
            popup: "swal-popup-oaktree",
            header: "swal-header-oaktree",
            title: "swal-title-oaktree",
            icon: "swal-icon-success-oaktree",
            image: "swal-image-oaktree",
            content: "swal-content-oaktree",
            input: "swal-input-oaktree",
            actions: "swal-actions-oaktree",
            confirmButton: "swal-confirm-button-oaktree",
            denyButton: "swal-confirm-button-oaktree",
            cancelButton: "swal-cancel-button-oaktree",
            footer: "swal-footer-oaktree",
          },
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.saveProfile();
            } else if (result.isCancelled) {
              this.$router.push("/Profile");
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
          });
      }
    },
    goTo() {
      this.$router.replace("Login");
    },

    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile(event) {
      let input = this.$refs.fileInput;
      let file = input.files;
      this.profileData.imageData = event.target.files[0];
      // console.log(event.target.files[0])
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        // this.$emit('input', file[0])
      }
    },
    gotoView() {
      this.$router.replace("/profile");
    },

    async saveProfile() {
      let formData = new FormData();
      formData.append("file", this.profileData.imageData);
      formData.append("email", this.profileData.email);
      formData.append("fullname", this.profileData.fullname);
      formData.append("country", this.profileData.country);
      formData.append("city", this.profileData.city);
      formData.append("phone_number", this.profileData.phoneNumber);
      formData.append("address", this.profileData.address);
      formData.append("id_shipment_access_user", this.userLogin.id);

      let { data } = axios
        .post(`${"/profile/store"}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res, "responsee");
          this.swalResponse(res.data);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    getProfileData() {
      this.getProfile(this.userLogin.id).then((res) => {
        console.log(res, "resProfile");
        this.profileData.fullname = res.fullname;
        this.profileData.email = this.$store.state.userLogin.email;
        this.profileData.phoneNumber = res.phone_number;
        this.profileData.city = res.city_id;
        this.profileData.country = res.country_id;
        this.profileData.address = res.address;
        // this.profile.imageData = res.image
        if (res.image) {
          this.previewImage = `data:image/jpeg;base64,${res.image}`;
        }
      });
    },
    async swalResponse(res) {
      if (res.status === true) {
        return new swal({
          title: "Success",
          text: res.message,
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#017283",
          showLoaderOnConfirm: false,
        })
          .then((res) => {
            console.log(res, "ress");
            this.$router.go();
          })
          .catch((error) => {
            Sentry.captureException(error);
          });
      } else {
        return new swal({
          title: "Failed",
          text: "failed",
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#017283",
          showLoaderOnConfirm: false,
        });
      }
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  background: #ffffff;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  cursor: pointer;
  color: #017283;
  width: 56px;
  height: 56px;
  font-size: 1.5rem;
  margin-top: -24px;
}
.img-fluid.img-profile {
  width: 11.875rem;
  height: 11.875rem;
  object-fit: cover;
  background-size: cover;
  border-radius: 1rem;
}
.btn-outline-success {
  background: #ffffff;
  color: #28ae96;
  border: 1px solid #28ae96;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  height: fit-content;
}
.btn-outline-success:hover {
  background: #28ae96;
  color: #ffffff;
  border: 1px solid #28ae96;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  height: fit-content;
}
.btn-success {
  background: #28ae96;
  border: 1px solid #28ae96;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  height: fit-content;
}
.btn-success:hover {
  opacity: 0.8;
  background: #28ae96;
  border: 1px solid #28ae96;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  height: fit-content;
}
.form-control,
.form-select {
  height: 50px;
  border: 1px solid #b0b0b0;
  border-radius: 2px;
}
.form-label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: left;
  color: #565656;
}
.slogan {
  font-size: 0.75rem;
  color: #585858;
}
.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.5rem;
}
::placeholder {
  font-size: 0.813rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
.auth-text-header h1 {
  font-size: 1.5rem !important;
  letter-spacing: 0.01em;
}
.auth-text-header p {
  font-size: 1rem !important;
  letter-spacing: 0.01em;
}
.fw-bold {
  font-weight: 900 !important;
}
p.text-small {
  font-size: 0.8rem;
  color: #585858;
}
form div h1.text-dark {
  color: #585858 !important;
}
.btn-primary.submit-form {
  background-color: #017283;
  border: 1px solid #017283;
}
.btn-primary.submit-form:hover {
  opacity: 0.8;
  background-color: #017283;
  border: 1px solid #017283;
}
.form-check-input {
  width: 1.5em;
  height: 1.5em;
}
.alt-text {
  font-size: 0.75rem;
  color: #707070;
}
.recovery-password {
  font-size: 0.75rem;
  color: #017283;
  text-decoration: none;
}
.recovery-password:hover {
  font-size: 0.75rem;
  color: #017283;
  opacity: 0.8;
}
.form-check-label {
  font-size: 0.875rem;
  color: #817f7f;
}
.border.inputed {
  border: 1px solid #5fc944 !important;
}
.input-group-text i {
  color: #817f7f;
}
.img-fluid.brand {
  width: 80px;
}
.input-group input:focus {
  box-shadow: none;
}
.form-control:focus {
  border-color: #ced4da;
}
.error-message {
  font-size: 0.8rem;
}

.custom-height >>> fieldset {
  height: 50px; /* Ubah angka sesuai tinggi yang Anda inginkan */
  /* border: 1px black */
  /* border: 1px solid #dcdcdc; */
}
.custom-height >>> .v-select__slot {
  display: flex;
  /* align-items: flex-start; */
  height: 40px; /* Ubah angka sesuai tinggi yang Anda inginkan */
}

.custom-height >>> .v-input__append-inner {
  position: absolute;
  right: 8px; /* Sesuaikan posisi panah sesuai kebutuhan */
  top: 20%;
  transform: translateY(-70%);
  pointer-events: none;
}

.custom-height {
  height: 20px;
}
</style>
