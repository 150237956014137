<template>
  <!-- <div
    class="col-sm-12 general-information px-2 px-md-4 py-2 py-md-3 rounded d-flex flex-row justify-content-between"
  >
    <div class="d-flex flex-row justify-content-center align-items-start gap-2">
      <img
        src="@/assets/images/general-information.png"
        class="img-fluild general-information-img"
      />
      <div
        class="d-flex flex-column general-information-text align-self-center"
      >
        <p class="font-roboto">General Information</p>
        <p class="font-roboto text-muted">See your cargo information</p>
      </div>
    </div>
    <button
      class="btn btn-warning rounded font-roboto px-3"
      data-bs-toggle="modal"
      data-bs-target="#trackingDetail"
    >
      Detail
    </button>
    

    <div
      class="modal fade"
      id="trackingDetail"
      tabindex="-1"
      aria-labelledby="trackingDetailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content px-3 px-md-4 py-2 py-md-3">
          <div class="modal-body row">
            <div
              class="col-sm-12 data-step d-flex flex-column number-step-wrapper mx-auto"
            >
              <div class="d-flex number-step justify-content-between">
                <div
                  class="d-flex flex-column gap-2 justify-content-center align-items-center"
                  :class="{ unconfirmed: numberStep != 1 }"
                >
                  <div class="icon-status-wrapper rounded-circle d-flex">
                    <span class="step-number mx-auto my-auto">1</span>
                  </div>
                  <p class="step-text font-roboto text-nowrap">Data Info</p>
                </div>
                <div
                  class="d-flex flex-column gap-2 justify-content-center align-items-center"
                  :class="{ unconfirmed: numberStep != 2 }"
                >
                  <div class="icon-status-wrapper rounded-circle d-flex">
                    <span class="step-number mx-auto my-auto">2</span>
                  </div>
                  <p class="step-text font-roboto text-nowrap">Sea</p>
                </div>
                <div
                  class="d-flex flex-column gap-2 justify-content-center align-items-center"
                  :class="{ unconfirmed: numberStep != 3 }"
                >
                  <div class="icon-status-wrapper rounded-circle d-flex">
                    <span class="step-number mx-auto my-auto">3</span>
                  </div>
                  <p class="step-text font-roboto text-nowrap">Volume</p>
                </div>
              </div>
              <div class="bar-status"></div>
            </div>

            
            <div class="col-sm-12 row my-4" v-if="numberStep == 1">
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Shipper :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Consigne :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Notify Party :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">SI/PO Number :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Receipt :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Loading :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Discharge :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Delivery :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Agent :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Freight :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Stuffing :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">DO Number :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Volume :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Notes :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Description of Goods :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
            </div>
            <div class="col-sm-12 row my-4" v-if="numberStep == 2">
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Shipping Line :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Vessel :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Booking Number :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">HBL Number :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">MBL Number :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Connecting Vessel :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Shipping Marks :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Issued Office :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
            </div>
            <div class="col-sm-12 row my-4" v-if="numberStep == 3">
              <div
                class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Volume :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Weight :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
              <div
                class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Unit :</p>
                <p class="font-roboto text-muted">Lorem ipsum</p>
              </div>
            </div>
          </div>
          <div
            class="modal-footer border-0 d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between"
          >
            <template v-if="numberStep == 1">
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-secondary px-4 py-2 font-roboto"
              >
                Back
              </button>
              <button
                class="btn btn-info align-self-stretch"
                :class="{ disabled: numberStep == 3 }"
                @click="addStep()"
              >
                <span class="text-nowrap">Next</span
                ><i class="bi bi-chevron-right ms-2"></i>
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-secondary px-4 py-2 font-roboto"
              >
                Back
              </button>
              <div
                class="d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between"
              >
                <button
                  class="btn btn-info align-self-stretch"
                  :class="{ disabled: numberStep == 1 }"
                  @click="backStep()"
                >
                  <i class="bi bi-chevron-left me-2"></i
                  ><span class="text-nowrap">Prev</span>
                </button>
                <button
                  class="btn btn-info align-self-stretch"
                  :class="{ disabled: numberStep == 3 }"
                  @click="addStep()"
                >
                  <span class="text-nowrap">Next</span
                  ><i class="bi bi-chevron-right ms-2"></i>
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div
    class="col-sm-12 general-information px-2 px-md-4 py-2 py-md-3 rounded d-flex flex-row justify-content-between"
  >
    <div class="d-flex flex-row justify-content-center align-items-start gap-2">
      <img
        src="@/assets/images/general-information.png"
        class="img-fluild general-information-img"
      />
      <div
        class="d-flex flex-column general-information-text align-self-center"
      >
        <p class="font-roboto">General Information</p>
        <p class="font-roboto text-muted">See your cargo information</p>
      </div>
    </div>
    <button
      class="btn btn-warning rounded font-roboto px-3"
      data-bs-toggle="modal"
      data-bs-target="#trackingDetail"
    >
      Detail
    </button>

    <div
      class="modal fade"
      id="trackingDetail"
      tabindex="-1"
      aria-labelledby="trackingDetailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content px-3 px-md-4 py-2 py-md-3">
          <div class="modal-body row">
            <div
              class="col-sm-12 data-step d-flex flex-column number-step-wrapper mx-auto"
            >
              <div class="d-flex number-step justify-content-between">
                <div
                  class="d-flex flex-column gap-2 justify-content-center align-items-center"
                  :class="{ unconfirmed: numberStep != 1 }"
                >
                  <div class="icon-status-wrapper rounded-circle d-flex">
                    <span class="step-number mx-auto my-auto">1</span>
                  </div>
                  <p class="step-text font-roboto text-nowrap">Data Info</p>
                </div>
                <div
                  class="d-flex flex-column gap-2 justify-content-center align-items-center"
                  :class="{ unconfirmed: numberStep != 2 }"
                >
                  <div class="icon-status-wrapper rounded-circle d-flex">
                    <span class="step-number mx-auto my-auto">2</span>
                  </div>
                  <p class="step-text font-roboto text-nowrap">
                    {{ generalInformation.id_sea ? "Sea" : "Air" }}
                  </p>
                </div>
                <!-- <div
                  class="d-flex flex-column gap-2 justify-content-center align-items-center"
                  :class="{ unconfirmed: numberStep != 3 }"
                >
                  <div class="icon-status-wrapper rounded-circle d-flex">
                    <span class="step-number mx-auto my-auto">3</span>
                  </div>
                  <p class="step-text font-roboto text-nowrap">Volume</p>
                </div> -->
              </div>
              <div class="bar-status"></div>
            </div>

            <div class="my-4" v-if="numberStep == 1">
              <div class="col-sm-12 row">
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Shipper :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.shipper
                        ? generalInformation.shipper
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Consigne :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.consignee
                        ? generalInformation.consignee
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Notify Party :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.notify_party
                        ? generalInformation.notify_party
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">SI/PO Number :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.si_number
                        ? generalInformation.si_number
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Receipt :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.receipt
                        ? generalInformation.receipt
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Loading :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.loading
                        ? generalInformation.loading
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Discharge :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.discharge
                        ? generalInformation.discharge
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Delivery :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.delivery
                        ? generalInformation.delivery
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Agent :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.agent_name
                        ? generalInformation.agent_name
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Freight :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.freight
                        ? getFreight(generalInformation.freight)
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Stuffing :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.stuffing
                        ? generalInformation.stuffing
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">DO Number :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.do_number
                        ? generalInformation.do_number
                        : "-"
                    }}
                  </p>
                </div>

                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                  v-if="generalInformation.type_jobfile !== 'FCL'"
                >
                  <p class="font-roboto">Volume :</p>
                  <div class="d-flex align-items-center justify-content-around">
                    <p class="font-roboto text-muted">
                      {{
                        generalInformation.volume_info
                          ? generalInformation.volume_info
                          : "-"
                      }}
                    </p>
                    <div
                      class="px-4 py-1 font-roboto volume_type rounded"
                    >
                      <p class="text-muted">
                        {{
                          generalInformation.volume_type
                            ? generalInformation.volume_type
                            : "-"
                        }}
                      </p>
                    </div>

                    <!-- <select
                      class="form-select range-date form-select-sm form-select-no-arrow"
                      aria-label="Default select example"
                      disabled
                    >
                      <option value="">
                        {{
                          generalInformation.volume_type
                            ? generalInformation.volume_type
                            : "-"
                        }}
                      </option>
                    </select> -->
                  </div>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Notes :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.notes ? generalInformation.notes : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Description of Goods :</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.desc_of_good
                        ? generalInformation.desc_of_good
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Package</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.package
                        ? generalInformation.package
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Type Package</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.package_type
                        ? generalInformation.package_type
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">Incoterms</p>
                  <p class="font-roboto text-muted">
                    {{
                      generalInformation.incoterms
                        ? generalInformation.incoterms
                        : "-"
                    }}
                  </p>
                </div>
                <div
                  class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
                >
                  <p class="font-roboto">AJU</p>
                  <p class="font-roboto text-muted">
                    {{ generalInformation.aju ? generalInformation.aju : "-" }}
                  </p>
                </div>
              </div>
              <div
                class="row general-information-text"
                v-if="generalInformation.type_jobfile === 'FCL'"
              >
                <div
                  v-for="(item, index) in generalInformation.volume"
                  :key="index"
                  class="col-12 d-flex flex-md-row flex-column general-information-text gap-3"
                >
                  <p class="font-roboto">Volume</p>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="item.qty"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="item.type_description"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="item.size"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="item.container_number"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="item.seal_number"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 row my-4" v-if="numberStep == 2">
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">
                  {{
                    generalInformation.id_sea ? "Shipping Line :" : "Airlines :"
                  }}
                </p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.id_sea === null
                      ? generalInformation.id_airlines !== null
                        ? generalInformation.id_airlines
                        : "-"
                      : generalInformation.id_shipping_line !== null
                      ? generalInformation.id_shipping_line
                      : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">
                  {{
                    generalInformation.id_sea ? "Vessel :" : "Flight Number :"
                  }}
                </p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.id_sea === null
                      ? generalInformation.flight_number !== null
                        ? generalInformation.flight_number
                        : "-"
                      : generalInformation.vessel !== null
                      ? generalInformation.vessel
                      : "-"
                  }}
                </p>
              </div>
              <div
                v-if="generalInformation.id_sea !== null"
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Booking Number :</p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.booking_number
                      ? generalInformation.booking_number
                      : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">
                  {{
                    generalInformation.id_sea ? "HBL Number :" : "HAWB Number :"
                  }}
                </p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.id_sea === null
                      ? generalInformation.hawb_number !== null
                        ? generalInformation.hawb_number
                        : "-"
                      : generalInformation.hbl_number !== null
                      ? generalInformation.hbl_number
                      : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">
                  {{
                    generalInformation.id_sea ? "MBL Number :" : "MAWB Number :"
                  }}
                </p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.id_sea === null
                      ? generalInformation.mawb_number !== null
                        ? generalInformation.mawb_number
                        : "-"
                      : generalInformation.mbl_number !== null
                      ? generalInformation.mbl_number
                      : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Connecting ETD :</p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.connecting_etd
                      ? generalInformation.connecting_etd
                      : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">
                  {{
                    generalInformation.id_sea
                      ? "Connecting Vessel :"
                      : "Connecting Flight Number :"
                  }}
                </p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.id_sea === null
                      ? generalInformation.connecting_flight_number !== null
                        ? generalInformation.connecting_flight_number
                        : "-"
                      : generalInformation.connecting_vessel !== null
                      ? generalInformation.connecting_vessel
                      : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">
                  {{
                    generalInformation.id_sea
                      ? "Shipping Marks :"
                      : "IATA Code:"
                  }}
                </p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.id_sea === null
                      ? generalInformation.iata_code !== null
                        ? generalInformation.iata_code
                        : "-"
                      : generalInformation.shipping_marks !== null
                      ? generalInformation.shipping_marks
                      : "-"
                  }}
                </p>
              </div>
              <div
                v-if="generalInformation.id_sea !== null"
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Issued Office :</p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.issued_office
                      ? generalInformation.issued_office
                      : "-"
                  }}
                </p>
              </div>

              <div
                v-if="generalInformation.id_sea !== null"
                class="col-4 mb-3 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Voyage :</p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.voyage_number
                      ? generalInformation.voyage_number
                      : "-"
                  }}
                </p>
              </div>
            </div>
            <!-- row justify-content-md-center -->
            <!-- <div class="col-sm-12 row my-4 m-auto" v-if="numberStep == 3">
              <div
                class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Volume :</p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.volume ? generalInformation.volume : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Weight :</p>
                <p class="font-roboto text-muted">
                  {{
                    generalInformation.weight ? generalInformation.weight : "-"
                  }}
                </p>
              </div>
              <div
                class="col-4 col-md-3 d-flex flex-column general-information-text align-self-center"
              >
                <p class="font-roboto">Unit :</p>
                <p class="font-roboto text-muted">
                  {{ generalInformation.unit ? generalInformation.unit : "-" }}
                </p>
              </div>
            </div> -->
          </div>
          <div
            class="modal-footer border-0 d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between"
          >
            <template v-if="numberStep == 1">
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-secondary px-4 py-2 font-roboto"
              >
                Back
              </button>
              <button
                class="btn btn-info-custom align-self-stretch"
                :class="{ disabled: numberStep == 3 }"
                @click="addStep()"
              >
                <span class="text-nowrap">Next</span
                ><i class="bi bi-chevron-right ms-2"></i>
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-secondary px-4 py-2 font-roboto"
              >
                Back
              </button>
              <button
                class="btn btn-info-custom align-self-stretch"
                :class="{ disabled: numberStep == 1 }"
                @click="backStep()"
              >
                <i class="bi bi-chevron-left me-2"></i
                ><span class="text-nowrap">Prev</span>
              </button>
              <!-- <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-secondary px-4 py-2 font-roboto"
              >
                Back
              </button>
              <div
                class="d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between"
              >
                <button
                  class="btn btn-info-custom align-self-stretch"
                  :class="{ disabled: numberStep == 1 }"
                  @click="backStep()"
                >
                  <i class="bi bi-chevron-left me-2"></i
                  ><span class="text-nowrap">Prev</span>
                </button>
                <button
                  class="btn btn-info-custom align-self-stretch"
                  :class="{ disabled: numberStep == 3 }"
                  @click="addStep()"
                >
                  <span class="text-nowrap">Next</span
                  ><i class="bi bi-chevron-right ms-2"></i>
                </button>
              </div> -->
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["generalInformation"],

  mounted() {
    // console.log("generalInformation Data:", this.generalInformation.volume[0].qty);
  },

  watch: {},

  data() {
    return {
      numberStep: 1,
    };
  },
  methods: {
    addStep() {
      this.numberStep++;
    },
    backStep() {
      this.numberStep--;
    },

    getFreight(type) {
      if (type == 1) return "Prepaid";
      if (type == 2) return "Collect";
      return type;
    },
  },
};
</script>

<style scoped>
/* mobile */
@media (min-width: 300px) {
  .bar-status {
    background-color: #eeeeee;
    height: 4px;
    width: 95%;
    margin: 0 auto;
    z-index: 1;
    margin-top: -50px !important;
  }
  .number-step-wrapper {
    width: 80%;
  }
}
/* tablet / horizontal mobile */
@media (min-width: 768px) {
  .bar-status {
    background-color: #eeeeee;
    height: 4px;
    width: 95%;
    margin: 0 auto;
    z-index: 1;
    margin-top: -50px !important;
  }
  .number-step-wrapper {
    width: 50%;
  }
}
.btn-secondary {
  background-color: #b3b3b3;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  border: transparent;
  color: #ffffff;
}
.btn-secondary:hover {
  border: transparent;
  opacity: 0.8;
  background-color: #b3b3b3;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
}
.unconfirmed {
  filter: grayscale(1) !important;
}
.data-step {
  height: 74px;
}
.data-step .number-step {
  z-index: 2;
}
/* general information */
.icon-status-wrapper {
  width: fit-content !important;
  z-index: 2;
  width: 48px !important;
  height: 48px !important;
  background-color: #017283;
  border: 6px solid #d4fae5;
}
.icon-status-wrapper .step-number {
  color: #ffffff;
}
.flex-column p.step-text {
  font-weight: 700;
  font-size: 12px;
  color: #017283;
}
.btn-info-custom {
  background: #017283 !important;
  box-shadow: 2px 2px 10px 2px rgba(81, 193, 173, 0.2);
  border-radius: 3px;
  color: #ffffff !important;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  border: transparent;
}
.btn-info-custom:hover {
  opacity: 0.8;
  background: #017283 !important;
  box-shadow: 2px 2px 10px 2px rgba(81, 193, 173, 0.2);
  border-radius: 3px;
  color: #ffffff !important;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  border: transparent;
}
.general-information button.btn-warning {
  color: #ffffff;
  background-color: #f9a707;
  font-weight: 500;
  font-size: 12px;
}
.general-information button.btn-warning:hover {
  color: #ffffff;
  background-color: #f9a707;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.8;
}
.col-sm-12.general-information {
  background: #fff2d9;
}
.img-fluild.general-information-img {
  width: 46px;
}
.general-information-text p {
  font-size: 12px;
  color: #585858;
  font-weight: 700;
}
.general-information-text p.text-muted {
  font-size: 12px;
  color: #817f7f;
  font-weight: 400;
}

.form-select.range-date {
  background-color: #eeeeee !important;
  border: 1px solid transparent;
  color: #000 !important;
  width: fit-content !important;
}
.form-select.range-date option {
  width: fit-content !important;
  color: #000 !important;
}

.volume_type {
  border: 2px solid rgb(202, 202, 203);
  background-color: #eeeeee;
  /* color: #000 !important; */
}
</style>
