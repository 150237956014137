var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-item dropdown notification"},[_c('a',{staticClass:"nav-link btn btn-primary position-relative d-flex justify-content-center align-items-center",attrs:{"href":"#","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('div',{staticClass:"my-auto"},[_c('svg',{attrs:{"width":"14","height":"16","viewBox":"0 0 14 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.99979 0C5.40849 0 3.88236 0.632141 2.75715 1.75736C1.63193 2.88258 0.999787 4.4087 0.999787 6V9.586L0.292787 10.293C0.152977 10.4329 0.057771 10.611 0.0192035 10.805C-0.0193641 10.9989 0.000438951 11.2 0.076109 11.3827C0.151779 11.5654 0.279918 11.7215 0.444328 11.8314C0.608738 11.9413 0.802037 12 0.999787 12H12.9998C13.1975 12 13.3908 11.9413 13.5552 11.8314C13.7197 11.7215 13.8478 11.5654 13.9235 11.3827C13.9991 11.2 14.0189 10.9989 13.9804 10.805C13.9418 10.611 13.8466 10.4329 13.7068 10.293L12.9998 9.586V6C12.9998 4.4087 12.3676 2.88258 11.2424 1.75736C10.1172 0.632141 8.59109 0 6.99979 0ZM6.99979 16C6.20414 16 5.44108 15.6839 4.87847 15.1213C4.31586 14.5587 3.99979 13.7956 3.99979 13H9.99979C9.99979 13.7956 9.68372 14.5587 9.12111 15.1213C8.5585 15.6839 7.79544 16 6.99979 16Z","fill":"#25AD99"}})])]),(
        this.notificationsToday.length !== 0 ||
        this.notificationsThisWeek.length !== 0
      )?_c('span',{staticClass:"position-absolute dot-notification top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"},[_c('span',{staticClass:"visually-hidden"},[_vm._v("unread messages")])]):_vm._e()]),_c('div',{staticClass:"dropdown-menu notification dropdown-menu-end row py-4 shadow"},[_c('div',{staticClass:"col-sm-12 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-lg-center notification-content"},[_c('h6',{staticClass:"fw-bold mb-4 mb-lg-0"},[_vm._v("Notification")]),_c('div',{staticClass:"d-flex align-self-stretch flex-row justify-content-between gap-0 gap-md-2 notification-actions"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.clearAll}},[_c('i',{staticClass:"bi bi-x me-1"}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Clear all")])])])]),_c('div',{staticClass:"col-sm-12 today-notification d-flex flex-column gap-2 px-2 m-0 px-0 mt-4 mt-md-2 card-notif overflow-auto"},[_vm._m(0),_vm._l((_vm.notificationsToday.slice(
          0,
          _vm.maxNotificationsToday
        )),function(notification,index){return [_c('CardNotification',{key:index + 'today',attrs:{"time":_vm.formatTime(notification.created_at),"id_sea":notification.id_sea,"jobfile_no":notification.jobfile_no,"name_port":notification.name_port,"country_port":notification.country_port,"bl_number":notification.bl_number,"hawb_number":notification.hawb_number,"status_shipment_title":notification.status_shipment_title,"status_timeline_title":notification.status_timeline_title,"status_timeline_type":notification.status_timeline_type,"status_shipment_icon":notification.status_shipment_icon,"isShow":notification.isShow,"id":notification.id},on:{"refresh-notif":_vm.refreshData}})]}),_vm._m(1),_vm._l((_vm.notificationsThisWeek.slice(
          0,
          _vm.maxNotificationsThisWeek
        )),function(notification,index){return [_c('CardNotification',{key:index + 'this_week',attrs:{"time":_vm.formatTime(notification.created_at),"id_sea":notification.id_sea,"jobfile_no":notification.jobfile_no,"name_port":notification.name_port,"country_port":notification.country_port,"bl_number":notification.bl_number,"hawb_number":notification.hawb_number,"status_shipment_title":notification.status_shipment_title,"status_timeline_title":notification.status_timeline_title,"status_timeline_type":notification.status_timeline_type,"status_shipment_icon":notification.status_shipment_icon,"isShow":notification.isShow,"id":notification.id},on:{"refresh-notif":_vm.refreshData}})]})],2),_c('a',{staticClass:"btn btn-primarys py-2 read-more-notification",attrs:{"href":"/notifications"}},[_vm._v("Read More")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('p',{staticClass:"fw-bold sub-title-notification"},[_vm._v("Today")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('p',{staticClass:"fw-bold sub-title-notification"},[_vm._v("This Week")])])
}]

export { render, staticRenderFns }