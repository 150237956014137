import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: "#1cb53d",
                secondary: "#63C132",
                default: "#707070",
                pale: "#EEEEEE",
                background: "#FFFFFF",
                royalblue: "#054677",
                royalblueyouth: "#4680AC",
                bluelagoon: "#017283",
                success: "#1AC431",
                info: "#0C77D5",
                error: "#F44336",
                warning: "#F44336",
                dark: "#585858",
                black: "#000000",
            },
        },
        options: { customProperties: true },
    },
});
