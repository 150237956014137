<template>
    <div>
        <ActivateAccount />
    </div>
</template>
  
<script>
import ActivateAccount from "@/components/Authentications/ActivateAccount.vue";
export default {
    name: "ActivateAccountView",
    components: {
        ActivateAccount,
    },
};
</script>
<style lang="scss" scoped>

</style>
  