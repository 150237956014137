<template>
    <div>
        <ChangePasswordComp />
    </div>
</template>
  
<script>
import ChangePasswordComp from "@/components/Authentications/ChangePassword.vue";
export default {
    name: "ChangePasswordView",
    components: {
        ChangePasswordComp,
    },
};
</script>
<style lang="scss" scoped>

</style>
  