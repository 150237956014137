<template>
  <header class="m-0 px-0 py-2">
    <nav class="navbar navbar-expand-lg d-none d-md-none d-lg-block">
      <div class="container">
        <a class="navbar-brand" href="/dashboard">
          <img :src="previewImage" alt="Logo" class="img-fluid" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav ms-auto gap-0 gap-md-4">
            <Notification />
            <Profile />
          </div>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg d-block d-md-block d-lg-none py-2">
      <div class="container px-0 d-flex justify-content-between">
        <div class="d-flex gap-2">
          <button
            class="navbar-toggler p-0 border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#drawer"
            aria-controls="drawer"
          >
            <img
              src="@/assets/icons/hamburger.svg"
              class="img-fluid img-drawer"
            />
          </button>
          <a class="navbar-brand" href="/dashboard">
            <img
              :src="previewImage"
              alt="Logo"
              class="img-fluid img-brand-mobile"
            />
          </a>
        </div>
        <div class="d-flex gap-2">
          <Notification />
          <Profile />
        </div>
      </div>
    </nav>
    <!-- OFFCANVAS -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="drawer"
      aria-labelledby="drawerLabel"
    >
      <div class="offcanvas-body d-flex flex-column gap-4">
        <a
          class="navbar-brand d-flex justify-content-center align-items-center"
          href="/dashboard"
        >
          <img :src="previewImage" alt="Logo" class="img-fluid" />
        </a>
        <MenuGroup forDrawer />
      </div>
    </div>
  </header>
</template>

<script>
import Notification from "./header/Notification.vue";
import Profile from "./header/Profile.vue";
import MenuGroup from "../general/MenuGroup.vue";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  components: { Notification, Profile, MenuGroup },
  data() {
    return {
      previewImage: `${require("@/assets/images/logo-oaktree-full.png")}`,
    };
  },
  mounted() {
    console.log("data login header", this.userLogin);
    if (this.userLogin.shipment_access.company.logo) {
      this.previewImage = this.userLogin.shipment_access.company.logo;
      console.log("data logo", this.previewImage)
    }
  },

  computed: {
    ...mapState(["userLogin"]),
  },
};
</script>

<style scoped>
.offcanvas.offcanvas-start {
  width: 80%;
}

.img-fluid.img-brand-mobile {
  width: 49px;
  height: 26px;
}
.img-fluid.img-drawer {
  width: 24px;
  height: 24px;
}
a img.img-fluid {
  height: 4.672rem;
  width: 6.75rem;
  object-fit: cover;
  background-size: cover;
}
nav {
  background-color: #f1f4f8;
}
</style>
