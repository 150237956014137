<template>
    <section class="container mx-auto row px-0 py-4 bg-white rounded gap-4 gap-md-0">
        <template v-if="!isEdited">
            <div class="col-sm-3 d-flex align-items-center justify-content-center">
                <img :src="dataProfile.imgUrl" class="img-fluid img-profile" />
            </div>
            <div class="col-sm-9 row">
                <CardSummary imgUrl="profile-fullname.svg" context="Full Name:" :value="dataProfile.fullName" textColor="#017283" />
                <CardSummary imgUrl="profile-email.svg" context="Email:" :value="dataProfile.email" textColor="#017283" />
                <CardSummary imgUrl="profile-expired.svg" context="Account Expired:" :value="dataProfile.accountExpired" textColor="#FF6D6D" />
                <CardSummary imgUrl="profile-phone.svg" context="Phone Number:" :value="dataProfile.phoneNumber" textColor="#017283" />
                <CardSummary imgUrl="profile-country.svg" context="Country:" :value="dataProfile.country" textColor="#017283" />
                <CardSummary imgUrl="profile-city.svg" context="City:" :value="dataProfile.city" textColor="#017283" />
                <CardSummary imgUrl="profile-address.svg" context="Address:" :value="dataProfile.address" textColor="#017283" />
                <div class="col-sm-12 d-flex justify-content-center justify-content-md-end align-items-center">
                    <button class="btn btn-success font-roboto px-5" @click="goEdit()">Edit</button>
                </div>
            </div>
        </template>
        <template v-else>            
            <FormProfile />            
        </template>
    </section>
</template>

<script>
    import dataProfile from "@/assets/json/profile/dataProfile.json"
    import CardSummary from "@/components/general/summary/CardSummary.vue"
    import FormProfile from "@/components/form/FormProfile.vue"
    export default {
        components: {CardSummary, FormProfile},
        data() {
            return {
                dataProfile: dataProfile,
                isEdited: false,
            }
        },
        methods: {
            goEdit() {
                this.isEdited = !this.isEdited
            }
        }
    }
</script>

<style scoped>    
    .img-fluid.img-profile {
        width: 11.875rem;
        height: 11.875rem;
        object-fit: cover;
        background-size: cover;
        border-radius: 1rem;
    }
    .btn-success {
        background: #28AE96;
        border: 1px solid #28AE96;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.01em;
        text-align: center;
        height: fit-content;
    }
    .btn-success:hover {
        opacity: 0.8;
        background: #28AE96;
        border: 1px solid #28AE96;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.01em;
        text-align: center;
        height: fit-content;
    }
</style>