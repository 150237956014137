import axios from "axios";
import * as Sentry from "@sentry/vue";

export const state = {
  shipmentTrackingInfo: [],
};

export const mutations = {
  setShipmentTrackingInfo(state, payload) {
    state.shipmentTrackingInfo = payload;
  },

  setError(state, payload) {
    state.errors = payload;
  },
};

export const actions = {
  getShipmentTrackingInfo: async (context, payload, getters) => {
    try {
      let { data } = await axios.get(
        "/shipment-access-user/get-shipment-tracking/detail/" + payload
      );

      context.commit("setShipmentTrackingInfo", data);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      context.commit("setError", error);
    }
  },
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
