<template>
    <div class="card-summary-wrapper col-sm-3 d-flex flex-row py-0 border-end align-self-center justify-content-start gap-4 mb-4 mb-md-0">
        <img :src="imgPath" alt="attribute" class="img-fluid img-summary-icon align-self-center" :class="{'is-forwarder' : isForwarder}" />
        <div class="d-flex flex-column gap-1 align-self-center summary-content">
            <p>{{context}}</p>
            <h6 class="fw-bold" :style="{ color: textColor}">{{ value }}</h6>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imgUrl: {
            type: String,
            default: ''
        },
        context: String,
        value: String,
        isForwarder: Boolean,
        textColor: String
    },
    computed: {
        imgPath() {            
            return require(`@/assets/images/${this.imgUrl}`);
        }
    }
}
</script>

<style scoped>
    @media (min-width: 300px) {
        .img-fluid.img-summary-icon.is-forwarder {
            width: 40px !important;
            height: 40px !important;
            border-radius: 6px;            
        }
    }
    @media (min-width: 992px) {
        .img-fluid.img-summary-icon.is-forwarder {
            width: 56px !important;
            height: 56px !important;
            border-radius: 6px;            
        }
    }
    .card-summary-wrapper {
        height: fit-content;
    }
    .summary-content p {
        font-size: 0.8rem;
        color: #AEAEAE;
    }
    .summary-content h6 {
        font-size: 0.9rem;
        color: #565656;
    }
    .img-fluid.img-summary {
        width: 40px;
        height: 40px;
        object-fit: cover;
        background-size: cover;
    }
    .img-fluid.img-summary-icon {
        width: 40px;
        height: 40px;
        object-fit: cover;
        background-size: cover;
    }
</style>