// import { createStore } from 'vuex'
import Vue from "vue";
import Vuex from "vuex";

// import state
// import state from "./state";
// import * as getters from "./getters";
// import * as actions from "./actions";
// import * as mutations from "./mutations";

//modules
// import auth from './modules/auth';
import loginBanner from "./modules/loginBanner";
import auth from "./modules/auth/index.js";
import city from "./modules/city/index.js";
import country from "./modules/country/index.js";
import profile from "./modules/profile/index.js";
import shipmentAccessUser from "./modules/shipmentAccessUser";
import shipmentTracking from "./modules/shipmentTracking";
import shipmentTrackingInfo from "./modules/shipmentTrackingInfo";
import dailyDeliveryOrder from "./modules/dailyDeliveryOrder";

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  // state,
  // getters,
  // mutations,
  // actions,
  strict: false,
  modules: {
    auth,
    loginBanner,
    shipmentAccessUser,
    city,
    country,
    profile,
    shipmentTracking,
    shipmentTrackingInfo,
    dailyDeliveryOrder,
    // customer
  },
  state: {
    rootApi: process.env.VUE_APP_ROOT_API,
    userLogin: localStorage.getItem("auth.currentUser")
      ? JSON.parse(localStorage.getItem("auth.currentUser"))
      : "",
    token: localStorage.getItem("token"),
    isLoggedIn: "",
  },
});

export default store;
