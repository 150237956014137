<template>
    <!-- FOOTER COPYRIGHT -->
    <footer class="container mx-auto px-2 px-md-5 py-4 d-flex flex-column flex-md-row justify-content-between">
        <p class="footer-text d-none d-md-block font-roboto"><span class="fw-bold">©{{footer.year}}  -  {{footer.nameApp}}</span> . All rights reserved</p>
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-center gap-2">
            <img src="@/assets/images/rimba.png" alt="rimba-company" class="img-fluid img-footer" />
            <p class="footer-text text-center font-roboto">Made with <span class="fw-bold">{{footer.author}}</span></p>
        </div>
    </footer>
    <!-- /FOOTER COPYRIGHT -->
</template>

<script>
    import footer from "@/assets/json/general/footer.json"
    export default {
        data() {
            return {
                footer: footer
            }
        }
    }
</script>

<style scoped>
    /* FOOTER */
    .img-fluid.img-footer {
        height: 18px;
    }
    .footer-text {
        font-size: 12px;
        color: #B0B0B0;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0.01em;
        text-align: left;
    }
</style>