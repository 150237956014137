<template>
    <div>
        <LoginComp />
    </div>
</template>
  
<script>
import LoginComp from "@/components/Authentications/Login.vue";
export default {
    name: "LoginView",
    components: {
        LoginComp,
    },
};
</script>
<style lang="scss" scoped>

</style>
  